/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect, useMemo } from 'react'
import {
  Flex,
  Heading,
  useDisclosure,
  useToast,
  Center,
  Box,
  Stack,
  Text,
  Select,
  Tab,
  Tabs,
  TabList,
  TabPanels,
  TabPanel,
  HStack,
  IconButton,
} from '@chakra-ui/react'
import {
  onSnapshot,
  query,
  collection,
  orderBy,
  doc,
  updateDoc,
} from 'firebase/firestore'
import { Table } from 'antd'
import { db } from 'config/firebase'
import { useParams } from 'react-router-dom'
import { AiFillEdit } from 'react-icons/ai'

import StatusModal from './Modal/StatusModal'

export default function KidPayment({ user }) {
  const { id } = useParams()

  const toast = useToast()

  const [isLoadingEvalution, setLoadingEvalution] = useState(true)
  const [evaluationList, setdataEvaluationList] = useState()

  const [isLoadingActive, setLoadingActive] = useState(true)
  const [activeList, setActiveList] = useState()

  const [isLoading, setLoading] = useState(true)
  const [courseList, setdataCourseList] = useState()

  const [modal, setModal] = useState()

  const {
    isOpen: isOpenModal,
    onOpen: onOpenModal,
    onClose: onCloseModal,
  } = useDisclosure()

  useEffect(() => {
    if (id) {
      const queryKid = query(
        collection(db, `Kids/${id}/Evaluation`),
        orderBy('createdAt', 'desc')
      )
      const unsubscribe = onSnapshot(queryKid, (snapShot) => {
        const data = []
        snapShot.forEach((snapShot) => {
          data.push({ ...snapShot.data(), id: snapShot.id })
        })
        setdataEvaluationList(data)
        setLoadingEvalution(false)
      })
      return () => {
        unsubscribe()
      }
    }
  }, [id, modal])

  useEffect(() => {
    if (id) {
      const queryKid = query(
        collection(db, `Kids/${id}/Status`),
        orderBy('createdAt', 'desc')
      )
      const unsubscribe = onSnapshot(queryKid, (snapShot) => {
        const data = []
        snapShot.forEach((snapShot) => {
          data.push({ ...snapShot.data(), id: snapShot.id })
        })
        setActiveList(data)
        setLoadingActive(false)
      })
      return () => {
        unsubscribe()
      }
    }
  }, [id, modal])

  useEffect(() => {
    if (id) {
      const queryKid = query(
        collection(db, `Kids/${id}/Programs`),
        orderBy('createdAt', 'desc')
      )
      const unsubscribe = onSnapshot(queryKid, (snapShot) => {
        const data = []
        snapShot.forEach((snapShot) => {
          data.push({ ...snapShot.data(), id: snapShot.id })
        })
        setdataCourseList(data)
        setLoading(false)
      })
      return () => {
        unsubscribe()
      }
    }
  }, [id, modal])

  const columns = useMemo(() => {
    return [
      {
        title: 'Evaluation',
        dataIndex: 'type',
        key: 'type',
        width: '20%',
      },
      {
        title: 'Evaluation Date',
        dataIndex: 'date',
        key: 'date',
        width: '20%',
      },
      {
        title: 'Evaluation Fee',
        dataIndex: 'fee',
        key: 'fee',
        width: '20%',
        render: (fee) => `${Number(fee).toLocaleString('th-TH')} บาท`,
      },
      {
        title: 'Evaluation Staff',
        dataIndex: 'staff',
        key: 'staff',
        width: '20%',
      },
      {
        title: 'Payment Status',
        dataIndex: 'payment',
        key: 'payment',
        align: 'center',
        width: '20%',
        render: (payment, row) => {
          return (
            <HStack>
              <Select
                value={payment?.status === 'paid' ? 'paid' : 'waiting'}
                onChange={(e) =>
                  handleStatusChange(e.target.value, row, 'Evaluation')
                }
              >
                <option value='waiting'>รอการชำระเงิน</option>
                <option value='paid'>ชำระเงินแล้ว</option>
              </Select>
            </HStack>
          )
        },
      },
      {
        title: 'Action',
        dataIndex: 'action',
        key: 'action',
        width: '10%',
        render: (data, row) => {
          return (
            row?.payment?.status === 'paid' && (
              <HStack>
                <IconButton
                  onClick={() => {
                    setModal({
                      id: row?.id,
                      payment: row?.payment,
                      collection: 'Evaluation',
                      status: 'paid',
                    })
                    onOpenModal()
                  }}
                  variant='link'
                  icon={<AiFillEdit />}
                />
              </HStack>
            )
          )
        },
      },
    ]
  }, [])

  const columnsActive = useMemo(() => {
    return [
      {
        title: '1st Treatment Date',
        dataIndex: 'activeDate',
        key: 'activeDate',
      },
      {
        title: 'Member Expire Date',
        dataIndex: 'expireDate',
        key: 'expireDate',
      },
      {
        title: 'Member Fee',
        dataIndex: 'fee',
        key: 'fee',
        render: (fee) => `${Number(fee).toLocaleString('th-TH')} บาท`,
      },

      {
        title: 'Status',
        dataIndex: 'status',
        key: 'status',
        render: (status) => (
          <Text
            textTransform={'uppercase'}
            color={status === 'member' && 'brand'}
          >
            {status}
          </Text>
        ),
      },
      {
        title: 'Payment Status',
        dataIndex: 'payment',
        key: 'payment',
        align: 'center',
        width: '20%',
        render: (payment, row) => {
          return (
            <HStack>
              <Select
                value={payment?.status === 'paid' ? 'paid' : 'waiting'}
                onChange={(e) =>
                  handleStatusChange(e.target.value, row, 'Status')
                }
              >
                <option value='waiting'>รอการชำระเงิน</option>
                <option value='paid'>ชำระเงินแล้ว</option>
              </Select>
            </HStack>
          )
        },
      },
      {
        title: 'Action',
        dataIndex: 'action',
        key: 'action',
        width: '10%',
        render: (data, row) => {
          return (
            row?.payment?.status === 'paid' && (
              <HStack>
                <IconButton
                  onClick={() => {
                    setModal({
                      id: row?.id,
                      payment: row?.payment,
                      collection: 'Status',
                      status: 'paid',
                    })
                    onOpenModal()
                  }}
                  variant='link'
                  icon={<AiFillEdit />}
                />
              </HStack>
            )
          )
        },
      },
    ]
    // eslint-disable-next-line
  }, [])

  const columnsCourse = useMemo(() => {
    return [
      {
        title: 'Course Name',
        dataIndex: 'name',
        key: 'name',
        width: '20%',
      },
      {
        title: 'Program',
        dataIndex: 'choice',
        key: 'choice',
        width: '20%',
      },
      {
        title: 'Price',
        dataIndex: 'price',
        key: 'price',
        width: '20%',
        render: (price) => `${Number(price).toLocaleString('th-TH')} บาท`,
      },

      {
        title: 'Payment Status',
        dataIndex: 'payment',
        key: 'payment',
        align: 'center',
        width: '20%',
        render: (payment, row) => {
          return (
            <HStack>
              <Select
                value={payment?.status === 'paid' ? 'paid' : 'waiting'}
                onChange={(e) =>
                  handleStatusChange(e.target.value, row, 'Programs')
                }
              >
                <option value='waiting'>รอการชำระเงิน</option>
                <option value='paid'>ชำระเงินแล้ว</option>
              </Select>
            </HStack>
          )
        },
      },
      {
        title: 'Action',
        dataIndex: 'action',
        key: 'action',
        width: '10%',
        render: (data, row) => {
          return (
            row?.payment?.status === 'paid' && (
              <HStack>
                <IconButton
                  onClick={() => {
                    setModal({
                      id: row?.id,
                      payment: row?.payment,
                      collection: 'Programs',
                      status: 'paid',
                    })
                    onOpenModal()
                  }}
                  variant='link'
                  icon={<AiFillEdit />}
                />
              </HStack>
            )
          )
        },
      },
    ]
    // eslint-disable-next-line
  }, [])

  function handleStatusChange(status, row, collection) {
    if (status === 'paid') {
      setModal({
        id: row.id,
        payment: {
          createdBy: user?.email,
        },
        collection,
        status,
      })
      onOpenModal()
    } else if (status === 'waiting') {
      handleUpdate(collection, row.id, {
        createdBy: '',
        createdAt: '',
        paymentNo: '',
        status: 'waiting',
      })
    }
  }

  function handleUpdate(collection, docId, payment) {
    updateDoc(
      doc(db, `Kids/${id}/${collection}`, docId),
      {
        payment,
      },
      { merge: true }
    )
      .then(() => {
        toast({
          position: 'top',
          title: 'Updated Status',
          description: 'Status has been Updatedd.',
          status: 'success',
          duration: 3000,
          isClosable: true,
        })
      })
      .catch((e) => {
        toast({
          position: 'top',
          title: 'Updated Fail.',
          description: e.message,
          status: 'error',
          duration: 3000,
          isClosable: true,
        })
      })
  }

  return (
    <>
      <Center my='10'>
        <Heading>PAYMENTS</Heading>
      </Center>
      {isOpenModal && (
        <StatusModal
          isOpen={isOpenModal}
          onClose={() => {
            onCloseModal()
            setModal(null)
          }}
          data={modal}
          user={user}
          onSubmit={(collection, docId, payment) =>
            handleUpdate(collection, docId, payment)
          }
        />
      )}

      <Stack spacing={10}>
        <Tabs variant='unstyled' mt={'2rem'}>
          <TabList>
            <Tab
              _focus={{ boxShadow: 'none' }}
              _selected={{ color: 'white', bg: 'brand' }}
              borderBottom='2px solid rgba(226, 232, 240, 1)'
            >
              Evaluation
            </Tab>
            <Tab
              _focus={{ boxShadow: 'none' }}
              _selected={{ color: 'white', bg: 'brand' }}
              borderBottom='2px solid rgba(226, 232, 240, 1)'
            >
              Member
            </Tab>
            <Tab
              _focus={{ boxShadow: 'none' }}
              _selected={{ color: 'white', bg: 'brand' }}
              borderBottom='2px solid rgba(226, 232, 240, 1)'
            >
              Courses
            </Tab>
          </TabList>
          <TabPanels>
            <TabPanel>
              <Box>
                <Heading as='h5' size='sm' my={2} color='brand'>
                  Payment for Kid Evaluation
                </Heading>
                <Box
                  borderWidth='1px'
                  borderColor={'brand'}
                  borderRadius='12px'
                  p={5}
                >
                  <Flex justifyContent='flex-end' w={'100%'} my={5}></Flex>
                  <Table
                    bordered
                    rowClassName={() => 'rowClassName1'}
                    size='small'
                    columns={columns}
                    dataSource={evaluationList}
                    loading={isLoadingEvalution}
                    rowKey='id'
                    scroll={{ x: 'max-content' }}
                  />
                </Box>
              </Box>
            </TabPanel>
            <TabPanel>
              <Box>
                <Heading as='h5' size='sm' my={2} color='brand'>
                  Payment for Kid Member
                </Heading>
                <Box
                  borderWidth='1px'
                  borderColor={'brand'}
                  borderRadius='12px'
                  p={5}
                >
                  <Flex justifyContent='flex-end' w={'100%'} my={5}></Flex>
                  <Table
                    bordered
                    style={{ borderRadius: '5px' }}
                    size='small'
                    dataSource={activeList}
                    columns={columnsActive}
                    loading={isLoadingActive}
                    rowKey='id'
                    scroll={{ x: 'max-content' }}
                  />
                </Box>
              </Box>
            </TabPanel>
            <TabPanel>
              <Box>
                <Heading as='h5' size='sm' my={2} color='brand'>
                  Payment for Course Programs
                </Heading>
                <Box
                  borderWidth='1px'
                  borderColor={'brand'}
                  borderRadius='12px'
                  p={5}
                >
                  <Flex justifyContent='flex-end' w={'100%'} my={5}></Flex>
                  <Table
                    bordered
                    rowClassName={() => 'rowClassName1'}
                    size='small'
                    columns={columnsCourse}
                    dataSource={courseList}
                    loading={isLoading}
                    rowKey='id'
                    scroll={{ x: 'max-content' }}
                  />
                </Box>
              </Box>
            </TabPanel>
          </TabPanels>
        </Tabs>
      </Stack>
    </>
  )
}
