import { Box, Flex, SkeletonCircle, SkeletonText } from '@chakra-ui/react'

export default function CardSkeletonFade() {
  return (
    <Box>
      <Flex
        alignItems='center'
        justifyContent='flex-start'
        bg='white'
        rounded='md'
        borderWidth='1px'
      >
        <Box
          py={4}
          px={1}
          position='relative'
          display='flex'
          justifyContent='center'
          alignItems='center'
          flex={2}
        >
          <SkeletonCircle size='60px' />
        </Box>
        <Box flex={6} pr={4} py={4}>
          <SkeletonText mt='4' noOfLines={5} spacing='3' />
        </Box>
      </Flex>
    </Box>
  )
}
