import { useState, useEffect, useMemo } from 'react'
import {
  Flex,
  Heading,
  Button,
  useDisclosure,
  Center,
  Box,
  Stack,
} from '@chakra-ui/react'
import {
  onSnapshot,
  query,
  collection,
  where,
  orderBy,
} from 'firebase/firestore'
import { Table } from 'antd'
import { useParams } from 'react-router-dom'
import { db } from 'config/firebase'
import AddCourseModal from './Modals/AddCourse'
import UpdateCourseModal from './Modals/UpdateCourse'

export default function CourseProgram() {
  const { id } = useParams()
  const [course, setCourse] = useState(null)

  const [isLoading, setLoading] = useState(true)
  const [courseList, setdataCourseList] = useState()
  const [staffs, setStaff] = useState([])

  const { isOpen, onOpen, onClose } = useDisclosure()
  const {
    isOpen: isOpenUpdate,
    onOpen: onOpenUpdate,
    onClose: onCloseUpdate,
  } = useDisclosure()

  useEffect(() => {
    if (id) {
      const queryKid = query(
        collection(db, `Kids/${id}/Programs`),
        orderBy('createdAt', 'desc')
      )
      const unsubscribe = onSnapshot(queryKid, (snapShot) => {
        const data = []
        snapShot.forEach((snapShot) => {
          data.push({ ...snapShot.data(), id: snapShot.id })
        })
        setdataCourseList(data)
        setLoading(false)
      })
      return () => {
        unsubscribe()
      }
    }
  }, [id])

  useEffect(() => {
    const queryStaff = query(
      collection(db, 'Profiles'),
      where('role', '==', 'Staff'),
      orderBy('createdAt', 'desc')
    )
    const unsubscribe = onSnapshot(queryStaff, (snapShot) => {
      let data = []
      snapShot.forEach((doc) => {
        data.push(doc.data().fullname)
      })
      setStaff(data)
    })
    return () => {
      unsubscribe()
    }
  }, [])

  const columns = useMemo(() => {
    return [
      {
        title: 'Course Name',
        dataIndex: 'name',
        key: 'name',
        width: '20%',
      },
      {
        title: 'Program',
        dataIndex: 'choice',
        key: 'choice',
        width: '20%',
      },
      {
        title: 'Taked Times',
        dataIndex: 'amount',
        key: 'amount',
        width: '10%',
        align: 'right',
        render: (amount, row) =>
          `${row.takedCourse.reduce(
            (total, course) => total + (course.isTaked ? 1 : 0),
            0
          )} / ${amount}`,
      },

      {
        title: '',
        dataIndex: 'Action',
        key: 'Action',
        width: '10%',
        align: 'center',
        render: (data, row) => {
          return (
            <Button
              variant='link'
              aria-label='edit'
              onClick={() => {
                setCourse(row)
                onOpenUpdate()
              }}
            >
              Manage
            </Button>
          )
        },
      },
    ]
    // eslint-disable-next-line
  }, [])

  return (
    <>
      <Center my='10'>
        <Heading>COURSES</Heading>
      </Center>

      <Stack spacing={10}>
        <Box>
          <Heading as='h5' size='sm' my={2} color='brand'>
            Course
          </Heading>
          <Box
            borderWidth='1px'
            borderColor={'brand'}
            borderRadius='12px'
            p={5}
          >
            {isOpen && (
              <AddCourseModal
                isOpen={isOpen}
                onClose={() => {
                  setCourse(null)
                  onClose()
                }}
                kidId={id}
              />
            )}
            {isOpenUpdate && (
              <UpdateCourseModal
                isOpen={isOpenUpdate}
                onClose={() => {
                  setCourse(null)
                  onCloseUpdate()
                }}
                data={course}
                kidId={id}
                staffs={staffs}
              />
            )}
            <Flex justifyContent='flex-end' w={'100%'} my={5}>
              <Button
                variant='solid'
                colorScheme='#F6872B'
                bg='#F6872B'
                onClick={onOpen}
              >
                Add Course
              </Button>
            </Flex>
            <Table
              bordered
              rowClassName={() => 'rowClassName1'}
              size='small'
              columns={columns}
              dataSource={courseList}
              loading={isLoading}
              rowKey='id'
              scroll={{ x: 'max-content' }}
            />
          </Box>
        </Box>
      </Stack>
    </>
  )
}
