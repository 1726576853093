const branchForm = [
  {
    label: 'Type',
    name: 'accountType',
    type: 'radio',
    placeholder: '',
    defaultValue: '',
    options: ['Head Quarter', 'Branch'],
    required: true,
  },
  {
    label: 'Branch Code',
    name: 'branchCode',
    type: 'text',
    placeholder: '',
    defaultValue: '',
    required: true,
  },
  {
    label: 'Branch Name',
    name: 'branchName',
    type: 'text',
    placeholder: '',
    defaultValue: '',
    required: true,
  },
  {
    label: 'Address',
    name: 'address',
    type: 'textarea',
    placeholder: '',
    defaultValue: '',
  },
  {
    label: 'Tax ID',
    name: 'taxId',
    type: 'text',
    placeholder: '',
    defaultValue: '',
    required: true,
  },
  {
    label: 'Phone Number',
    name: 'phoneNumber',
    type: 'text',
    placeholder: '',
    defaultValue: '',
  },
]

const bankForm = [
  {
    label: 'ธนาคาร',
    name: 'bank',
    type: 'text',
    placeholder: '',
    defaultValue: '',
    required: true,
  },
  {
    label: 'เลขที่บัญชี',
    name: 'accountNo',
    type: 'text',
    placeholder: '',
    defaultValue: '',
    required: true,
  },
  {
    label: 'สาขาธนาคาร',
    name: 'branch',
    type: 'text',
    placeholder: '',
    defaultValue: '',
    required: true,
  },
  {
    label: 'ชื่อบัญชี',
    name: 'accountName',
    type: 'text',
    placeholder: '',
    defaultValue: '',
    required: true,
  },
  {
    label: 'ประเภทบัญชี',
    name: 'accountType',
    type: 'radio',
    placeholder: '',
    defaultValue: '',
    options: ['ออมทรัพย์', 'กระแสรายวัน', 'ฝากประจำ'],
    required: true,
  },
]

const cashForm = [
  {
    label: 'ผู้ให้บริการ/ชื่อสาขา',
    name: 'name',
    type: 'text',
    placeholder: '',
    defaultValue: '',
    required: true,
  },
  {
    label: 'รายละเอียด',
    name: 'description',
    type: 'textarea',
    placeholder: '',
    defaultValue: '',
    required: false,
  },
]

export { branchForm, bankForm, cashForm }
