/* eslint-disable react-hooks/exhaustive-deps */
import { useMemo, useState, useEffect, useRef } from 'react'
import {
  Center,
  Button,
  Box,
  HStack,
  Heading,
  SimpleGrid,
  FormControl,
  Input,
  FormLabel,
  useToast,
  Spinner,
  Avatar,
  Fade,
  Flex,
  IconButton,
  useDisclosure,
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
  AlertDialogCloseButton,
} from '@chakra-ui/react'
import { useForm } from 'react-hook-form'
import { Link, useNavigate, useParams } from 'react-router-dom'
import { db, storage } from 'config/firebase'
import { ref, getDownloadURL, uploadBytes } from 'firebase/storage'
import {
  onSnapshot,
  query,
  doc,
  collection,
  orderBy,
  writeBatch,
  increment,
} from 'firebase/firestore'
import moment from 'moment'
import Resizer from 'react-image-file-resizer'

import { RiPrinterFill } from 'react-icons/ri'
import { BiArrowBack } from 'react-icons/bi'

import FormInputs from 'components/DynamicForm'
import {
  infoForm1,
  infoForm2,
  backgroundForm,
  parent1Form,
  parent2Form,
  parentExtraForm,
} from './fields'

const resizeFile = (file) =>
  new Promise((resolve) => {
    Resizer.imageFileResizer(
      file,
      300,
      300,
      'jpg',
      100,
      0,
      (uri) => {
        resolve(uri)
      },
      'file'
    )
  })

export default function KidForm() {
  const navigate = useNavigate()
  const { id } = useParams()
  const toast = useToast()
  const [isLoading, setLoading] = useState(false)
  const [isLoadingData, setLoadingData] = useState(false)
  const [data, setdata] = useState()
  const [fileUpload, setFileUpload] = useState(null)
  const hiddenFileInput = useRef()
  const [branches, setBranches] = useState()
  const [branchesQuery, setBranchesQuery] = useState([])
  const { isOpen, onOpen, onClose } = useDisclosure()
  const cancelRef = useRef()

  const {
    handleSubmit,
    formState: { errors },
    control,
    getValues,
    watch,
  } = useForm()

  useEffect(() => {
    if (id) {
      const queryKid = query(doc(db, 'Kids', id))
      const unsubscribe = onSnapshot(queryKid, (snapShot) => {
        setdata({
          ...snapShot.data(),
          id: snapShot.id,
        })

        setLoadingData(false)
      })
      return () => {
        unsubscribe()
      }
    }
  }, [id])

  useEffect(() => {
    const queryBranches = query(
      collection(db, 'Branches'),
      orderBy('createdAt', 'desc')
    )
    const unsubscribe = onSnapshot(queryBranches, (snapShot) => {
      let data = [
        {
          label: 'สาขา',
          name: 'branch',
          type: 'dropdown',
          options: [],
          placeholder: '',
          defaultValue: '',
          required: true,
        },
      ]
      let query = []
      snapShot.forEach((doc) => {
        query.push({ ...doc.data(), id: doc.id })
        data[0].options.push(doc.data().branchName)
      })
      setBranchesQuery(query)
      setBranches(data)
    })
    return () => {
      unsubscribe()
    }
  }, [])

  const watchBirthday = watch(`birthday`)

  const age = useMemo(() => {
    const birthday = getValues('birthday') || data?.birthday
    const years = Math.abs(
      moment(new Date()).diff(moment(birthday, 'YYYY-MM-DD'), 'years', true)
    )

    if (!isNaN(years)) {
      return `${years.toFixed()} ปี ${years.toString().split('.')[1][0]} เดือน`
    } else {
      return ''
    }
  }, [watchBirthday, data?.birthday])

  function submit(values) {
    setLoading(true)
    if (data?.id) {
      updateKid(values)
    } else {
      createKid(values)
    }
  }

  function handleUploadClick() {
    hiddenFileInput.current.click()
  }

  async function handleUploadChange(e) {
    e.preventDefault()
    const fileUploaded = e.target.files[0]
    if (
      fileUploaded?.type === 'image/jpeg' ||
      fileUploaded?.type === 'image/png'
    ) {
      const resizeFileUpload = await resizeFile(fileUploaded)
      setFileUpload(resizeFileUpload)
    } else {
      setFileUpload(null)
      toast({
        title: 'อัพโหลดไฟล์',
        position: 'top',
        description: 'ใช้ไฟล์รูปภาพ JPG, JPEG เท่านั้น',
        type: 'info',
        duration: 4000,
        isClosable: true,
      })
    }
  }

  async function createKid(values) {
    try {
      const batch = writeBatch(db)
      const selectBranch = branchesQuery.filter(
        (branches) => branches.branchName === values.branch
      )
      const kidId = String(
        selectBranch[0].counters ? selectBranch[0].counters + 1 : 1
      ).padStart(4, '0')
      const branchRef = doc(db, 'Branches', selectBranch[0].id)
      const kidRef = doc(db, 'Kids', `${selectBranch[0].branchName}-${kidId}`)

      if (fileUpload) {
        const promises = []
        const storageRef = ref(
          storage,
          `Kids/${kidId}/${new Date().getTime()}-${fileUpload.name}`
        )
        const uploadTask = uploadBytes(storageRef, fileUpload)

        promises.push(
          uploadTask
            .then((uploadResult) => {
              return getDownloadURL(uploadResult.ref)
            })
            .then((url) => url)
        )

        const downloadURL = await Promise.all(promises)

        batch.set(kidRef, {
          ...values,
          createdAt: new Date(),
          picture: downloadURL,
          status: 'evaluation',
        })
      } else {
        batch.set(kidRef, {
          ...values,
          createdAt: new Date(),
          status: 'evaluation',
        })
      }
      batch.set(branchRef, { counters: increment(1) }, { merge: true })

      await batch.commit()
      setLoading(false)
      toast({
        position: 'top',
        title: 'Create Kid',
        description: 'Kid has been created.',
        status: 'success',
        duration: 3000,
        isClosable: true,
      })
      navigate('/kids')
    } catch (e) {
      setLoading(false)
      toast({
        position: 'top',
        title: 'Create Fail.',
        description: e.message,
        status: 'error',
        duration: 3000,
        isClosable: true,
      })
    }
  }

  async function updateKid(values) {
    try {
      const batch = writeBatch(db)

      const kidRef = doc(db, 'Kids', `${data.id}`)

      if (fileUpload) {
        const promises = []
        const storageRef = ref(
          storage,
          `Kids/${data.id}/${new Date().getTime()}-${fileUpload.name}`
        )
        const uploadTask = uploadBytes(storageRef, fileUpload)

        promises.push(
          uploadTask
            .then((uploadResult) => {
              return getDownloadURL(uploadResult.ref)
            })
            .then((url) => url)
        )

        const downloadURL = await Promise.all(promises)

        batch.set(
          kidRef,
          {
            ...values,
            updatedAt: new Date(),
            picture: downloadURL,
          },
          { merge: true }
        )
      } else {
        batch.set(kidRef, { ...values, updatedAt: new Date() }, { merge: true })
      }

      await batch.commit()
      setLoading(false)
      toast({
        position: 'top',
        title: 'Update Kid',
        description: 'Kid has been updated.',
        status: 'success',
        duration: 3000,
        isClosable: true,
      })
      navigate('/kids')
    } catch (e) {
      setLoading(false)
      toast({
        position: 'top',
        title: 'Update Fail.',
        description: e.message,
        status: 'error',
        duration: 3000,
        isClosable: true,
      })
    }
  }

  if (isLoadingData || (id && !data) || !branches) {
    return (
      <Center h='100vh'>
        <Spinner />
      </Center>
    )
  }

  return (
    <Fade initialscale={1.5} in={!isLoadingData && branches}>
      <AlertDialog
        motionPreset='slideInBottom'
        leastDestructiveRef={cancelRef}
        onClose={onClose}
        isOpen={isOpen}
        isCentered
      >
        <AlertDialogOverlay />

        <AlertDialogContent>
          <AlertDialogHeader>Discard Changes?</AlertDialogHeader>
          <AlertDialogCloseButton />
          <AlertDialogBody>
            Are you sure you want to discard all of your input
          </AlertDialogBody>
          <AlertDialogFooter>
            <Button ref={cancelRef} onClick={onClose}>
              No
            </Button>
            <Link to={'/kids'}>
              <Button colorScheme='red' ml={3}>
                Yes
              </Button>
            </Link>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>
      <Box p={id ? 0 : 10}>
        {!id && (
          <Flex justifyContent='flex-start'>
            <IconButton
              size={30}
              variant='ghost'
              color='brand'
              icon={<BiArrowBack size={30} />}
              onClick={() => navigate('/kids')}
            />
          </Flex>
        )}
        <Center>
          <Heading my={10}>APPLICATION</Heading>
        </Center>
        {id && (
          <Flex justifyContent='flex-end'>
            <IconButton
              size={50}
              variant='ghost'
              color='brand'
              icon={<RiPrinterFill size={50} />}
            ></IconButton>
          </Flex>
        )}
        <Heading as='h5' size='sm' my={2} color='brand'>
          Basic Information
        </Heading>
        <Box
          p={5}
          borderRadius={10}
          mb={10}
          borderWidth='1px'
          borderColor='brand'
        >
          <Center my={8}>
            {fileUpload ? (
              <Avatar
                src={URL.createObjectURL(fileUpload)}
                cursor='pointer'
                bg='gray.200'
                size='2xl'
                onClick={handleUploadClick}
              />
            ) : data?.picture ? (
              <Avatar
                src={data?.picture}
                cursor='pointer'
                bg='gray.200'
                size='2xl'
                onClick={handleUploadClick}
              />
            ) : (
              <Avatar
                cursor='pointer'
                bg='gray.200'
                size='2xl'
                onClick={handleUploadClick}
              />
            )}
            <Input
              type='file'
              display='none'
              ref={hiddenFileInput}
              onChange={handleUploadChange}
            />
          </Center>

          <Box>
            <SimpleGrid
              columns={[1, 1, 2, 2]}
              spacingX={[1, 1, 1, '25px']}
              justifyContent='center'
              alignItems='center'
            >
              <FormInputs
                errors={errors}
                control={control}
                forms={branches || []}
                data={data}
              />
            </SimpleGrid>
            <SimpleGrid
              columns={[1, 1, 2, 2]}
              spacingX={[1, 1, 1, '25px']}
              justifyContent='center'
              alignItems='center'
            >
              <FormInputs
                errors={errors}
                control={control}
                forms={infoForm1 || []}
                data={data}
              />

              <FormControl id={`age`} my={3}>
                <FormLabel>อายุ</FormLabel>
                <Input name={`age`} type={'text'} value={age} readOnly />
              </FormControl>
              <FormInputs
                errors={errors}
                control={control}
                forms={infoForm2 || []}
                data={data}
              />
            </SimpleGrid>
          </Box>
        </Box>
        <Heading as='h5' size='sm' my={2} color='brand'>
          Background
        </Heading>
        <Box
          p={5}
          borderRadius={10}
          mb={10}
          borderWidth='1px'
          borderColor='brand'
        >
          <Box>
            <FormInputs
              errors={errors}
              control={control}
              forms={
                [
                  {
                    label: 'ที่อยุ่',
                    name: 'address',
                    type: 'textarea',
                    placeholder: '',
                    defaultValue: '',
                    required: false,
                  },
                ] || []
              }
              data={data}
            />
            <SimpleGrid
              columns={[1, 1, 2, 2]}
              spacingX={[1, 1, 1, '25px']}
              justifyContent='center'
              alignItems='center'
            >
              <FormInputs
                errors={errors}
                control={control}
                forms={backgroundForm || []}
                data={data}
              />
            </SimpleGrid>
          </Box>
        </Box>
        <Heading as='h5' size='sm' my={2} color='brand'>
          Parent
        </Heading>
        <Box
          p={5}
          borderRadius={10}
          mb={10}
          borderWidth='1px'
          borderColor='brand'
        >
          <SimpleGrid
            columns={[1, 1, 2, 2]}
            spacingX={[1, 1, 1, '25px']}
            justifyContent='center'
            alignItems='center'
          >
            <Box>
              <Heading as='h6' size='sm' my={2} color='brand'>
                ผู้ปกครอง 1
              </Heading>
              <FormInputs
                errors={errors}
                control={control}
                forms={parent1Form || []}
                data={data}
              />
            </Box>
            <Box>
              <Heading as='h6' size='sm' my={2} color='brand'>
                ผู้ปกครอง 2
              </Heading>
              <FormInputs
                errors={errors}
                control={control}
                forms={parent2Form || []}
                data={data}
              />
            </Box>
            <Box>
              <Heading as='h6' size='sm' my={2} color='#F6872B'>
                ข้อมูลการติดต่อกรณีฉุกเฉิน นอกเหนือจากผู้ปกครองหลัก
              </Heading>
              <FormInputs
                errors={errors}
                control={control}
                forms={parentExtraForm || []}
                data={data}
              />
            </Box>
          </SimpleGrid>
        </Box>

        <HStack justifyContent='center' my={10}>
          <Button mr={3} variant='outline' onClick={onOpen}>
            Cancel
          </Button>
          <Button
            colorScheme='lime'
            bg='brand'
            onClick={handleSubmit(submit)}
            isLoading={isLoading}
          >
            Submit
          </Button>
        </HStack>
      </Box>
    </Fade>
  )
}
