import { useState, useEffect } from 'react'
import { Routes, Route, Navigate } from 'react-router-dom'
import { onAuthStateChanged } from 'firebase/auth'
import { Center, VStack, Image, Heading } from '@chakra-ui/react'

import Layout from 'layout'
import AuthLayout from 'layout/authLayout'
import { doc, onSnapshot } from 'firebase/firestore'

import { auth, db } from 'config/firebase'

import Settings from 'pages/Settings'
import BracnhSetting from 'pages/Settings/Branch'
import AdminSetting from 'pages/Settings/Admin'
import StaffSetting from 'pages/Settings/Staff'
import StaffForm from 'pages/Settings/Staff/form'
import CourseSetting from 'pages/Settings/Cousre'
import CourseForm from 'pages/Settings/Cousre/form'

import Kids from 'pages/Kids'
import KidForm from 'pages/Kids/form'
import KidInfo from 'pages/Kids/info'

import Payment from 'pages/Payments'
import PaymentForm from 'pages/Payments/form'

import Login from 'pages/Auth/login'

// import './App.css'

function PrivateRoute({ children, user, allowed, notAllowed }) {
  if (!user) {
    // Redirect them to the /login page, but save the current location they were
    // trying to go to when they were redirected. This allows us to send them
    // along to that page after they login, which is a nicer user experience
    // than dropping them off on the home page.
    return <Navigate to='/login' replace />
  }

  return <Layout user={user}>{children}</Layout>
}

function PublicRoute({ children, bg, user }) {
  if (user) {
    return <Navigate to='/kids' replace />
  }

  return <AuthLayout bg={bg}>{children}</AuthLayout>
}

function App() {
  const [user, setUser] = useState(null)
  const [isLoading, setLoading] = useState(true)

  useEffect(() => {
    onAuthStateChanged(auth, async (authUser) => {
      if (authUser) {
        let idToken = await authUser.getIdTokenResult()
        let uid = authUser.uid
        setUser({ ...authUser })
        onSnapshot(doc(db, 'Profiles', uid), async (doc) => {
          setUser({ ...authUser, ...doc.data(), role: idToken?.claims?.role })
          setLoading(false)
        })
      } else {
        setLoading(false)
        setUser(null)
      }
    })
  }, [])

  if (isLoading) {
    return (
      <Center h='100vh' width='full' bg='brand'>
        <VStack spacing={5}>
          <Image height='80px' src='/Logo.webp' />
          <Heading color='white'>Loading ...</Heading>
        </VStack>
      </Center>
    )
  }

  return (
    <Routes>
      <Route
        path='/payments/bill/create'
        element={
          <PrivateRoute user={user}>
            <PaymentForm type='bill' user={user} />
          </PrivateRoute>
        }
      />
      <Route
        path='/payments/receipt/create'
        element={
          <PrivateRoute user={user}>
            <PaymentForm type='receipt' user={user} />
          </PrivateRoute>
        }
      />
      <Route
        path='/payments'
        element={
          <PrivateRoute user={user}>
            <Payment />
          </PrivateRoute>
        }
      />
      <Route
        path='/settings/course/edit/:id'
        element={
          <PrivateRoute user={user}>
            <CourseForm />
          </PrivateRoute>
        }
      />
      <Route
        path='/settings/course/create'
        element={
          <PrivateRoute user={user}>
            <CourseForm />
          </PrivateRoute>
        }
      />
      <Route
        path='/settings/course'
        element={
          <PrivateRoute user={user}>
            <CourseSetting />
          </PrivateRoute>
        }
      />
      <Route
        path='/settings/staff/edit/:id'
        element={
          <PrivateRoute user={user}>
            <StaffForm />
          </PrivateRoute>
        }
      />
      <Route
        path='/settings/staff/create'
        element={
          <PrivateRoute user={user}>
            <StaffForm />
          </PrivateRoute>
        }
      />
      <Route
        path='/settings/staff'
        element={
          <PrivateRoute user={user}>
            <StaffSetting />
          </PrivateRoute>
        }
      />
      <Route
        path='/settings/admin'
        element={
          <PrivateRoute user={user}>
            <AdminSetting />
          </PrivateRoute>
        }
      />
      <Route
        path='/settings/branch'
        element={
          <PrivateRoute user={user}>
            <BracnhSetting />
          </PrivateRoute>
        }
      />
      <Route
        path='/settings'
        element={
          <PrivateRoute user={user}>
            <Settings />
          </PrivateRoute>
        }
      />
      <Route
        path='/kids/:id'
        element={
          <PrivateRoute user={user}>
            <KidInfo user={user} />
          </PrivateRoute>
        }
      />
      <Route
        path='/kids/create'
        element={
          <PrivateRoute user={user}>
            <KidForm />
          </PrivateRoute>
        }
      />
      <Route
        path='/kids'
        element={
          <PrivateRoute user={user}>
            <Kids />
          </PrivateRoute>
        }
      />
      <Route
        path='/login'
        element={
          <PublicRoute bg={'brand'} user={user}>
            <Login />
          </PublicRoute>
        }
      />
      <Route path='*' element={<Navigate to='/kids' replace />} />
    </Routes>
  )
}

export default App
