import { useState, useRef } from 'react'
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  useToast,
  Avatar,
  Center,
  Input,
} from '@chakra-ui/react'
import { useForm } from 'react-hook-form'
import Resizer from 'react-image-file-resizer'
import { db, storage } from 'config/firebase'
import { doc, addDoc, collection, writeBatch } from 'firebase/firestore'
import { ref, getDownloadURL, uploadBytes } from 'firebase/storage'

import FormInputs from 'components/DynamicForm'
import { branchForm } from '../fields'

const resizeFile = (file) =>
  new Promise((resolve) => {
    Resizer.imageFileResizer(
      file,
      300,
      300,
      'jpg',
      100,
      0,
      (uri) => {
        resolve(uri)
      },
      'file'
    )
  })

export default function BranchModal({ isOpen, onClose, data }) {
  const [isLoading, setLoading] = useState(false)
  const toast = useToast()
  const [fileUpload, setFileUpload] = useState(null)
  const hiddenFileInput = useRef()
  const {
    handleSubmit,
    formState: { errors },
    control,
    resetField,
  } = useForm()

  function handleUploadClick() {
    hiddenFileInput.current.click()
  }

  async function handleUploadChange(e) {
    e.preventDefault()
    const fileUploaded = e.target.files[0]
    if (
      fileUploaded?.type === 'image/jpeg' ||
      fileUploaded?.type === 'image/png'
    ) {
      const resizeFileUpload = await resizeFile(fileUploaded)
      setFileUpload(resizeFileUpload)
    } else {
      setFileUpload(null)
      toast({
        title: 'อัพโหลดไฟล์',
        position: 'top',
        description: 'ใช้ไฟล์รูปภาพ JPG, JPEG เท่านั้น',
        type: 'info',
        duration: 4000,
        isClosable: true,
      })
    }
  }

  function submit(values) {
    if (data?.id) {
      updateBranch(values)
    } else {
      createBranch(values)
    }
  }

  async function createBranch(values) {
    try {
      const batch = writeBatch(db)

      const newBranch = await addDoc(collection(db, 'Branches'), {
        ...values,
        createdAt: new Date(),
      }).then((branch) => branch)

      const branchRef = doc(db, 'Branches', newBranch.id)

      if (fileUpload) {
        const promises = []
        const storageRef = ref(
          storage,
          `Branches/${newBranch.id}/${new Date().getTime()}-${fileUpload.name}`
        )
        const uploadTask = uploadBytes(storageRef, fileUpload)

        promises.push(
          uploadTask
            .then((uploadResult) => {
              return getDownloadURL(uploadResult.ref)
            })
            .then((url) => url)
        )

        const downloadURL = await Promise.all(promises)

        batch.set(branchRef, {
          ...values,
          createdAt: new Date(),
          picture: downloadURL,
        })
      } else {
        batch.set(branchRef, {
          ...values,
          createdAt: new Date(),
        })
      }

      await batch.commit()
      toast({
        position: 'top',
        title: 'Create Account',
        description: 'Branch has been created.',
        status: 'success',
        duration: 3000,
        isClosable: true,
      })
      setLoading(false)
      resetField()
      onClose()
    } catch (e) {
      setLoading(false)
      toast({
        position: 'top',
        title: 'Create Fail.',
        description: e.message,
        status: 'error',
        duration: 3000,
        isClosable: true,
      })
    }
  }

  async function updateBranch(values) {
    try {
      setLoading(true)
      const batch = writeBatch(db)

      const branchRef = doc(db, 'Branches', data.id)

      if (fileUpload) {
        const promises = []
        const storageRef = ref(
          storage,
          `Kids/${data.id}/${new Date().getTime()}-${fileUpload.name}`
        )
        const uploadTask = uploadBytes(storageRef, fileUpload)

        promises.push(
          uploadTask
            .then((uploadResult) => {
              return getDownloadURL(uploadResult.ref)
            })
            .then((url) => url)
        )

        const downloadURL = await Promise.all(promises)

        batch.set(branchRef, {
          ...values,
          createdAt: new Date(),
          picture: downloadURL,
        })
      } else {
        batch.set(branchRef, {
          ...values,
          createdAt: new Date(),
        })
      }

      await batch.commit()
      toast({
        position: 'top',
        title: 'Update Branch',
        description: 'Branch has been updated.',
        status: 'success',
        duration: 3000,
        isClosable: true,
      })
      setLoading(false)
      resetField()
      onClose()
    } catch (e) {
      toast({
        position: 'top',
        title: 'Update Fail.',
        description: e.message,
        status: 'error',
        duration: 3000,
        isClosable: true,
      })
      setLoading(false)
    }
  }

  return (
    <>
      <Modal
        isOpen={isOpen}
        onClose={onClose}
        size={'lg'}
        closeOnOverlayClick={false}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader textAlign='center'>CREATE BRANCH</ModalHeader>
          <ModalCloseButton />
          <ModalBody p={10}>
            <Center>
              {fileUpload ? (
                <Avatar
                  src={URL.createObjectURL(fileUpload)}
                  cursor='pointer'
                  bg='gray.200'
                  size='2xl'
                  onClick={handleUploadClick}
                />
              ) : data?.picture ? (
                <Avatar
                  src={data?.picture}
                  cursor='pointer'
                  bg='gray.200'
                  size='2xl'
                  onClick={handleUploadClick}
                />
              ) : (
                <Avatar
                  cursor='pointer'
                  bg='gray.200'
                  size='2xl'
                  onClick={handleUploadClick}
                />
              )}
              <Input
                type='file'
                display='none'
                ref={hiddenFileInput}
                onChange={handleUploadChange}
              />
            </Center>
            <FormInputs
              errors={errors}
              control={control}
              forms={branchForm || []}
              data={data}
            />
          </ModalBody>

          <ModalFooter justifyContent='center'>
            <Button mr={3} variant='outline' onClick={onClose}>
              Close
            </Button>
            <Button
              colorScheme='lime'
              onClick={handleSubmit(submit)}
              bg='brand'
              isLoading={isLoading}
            >
              Submit
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  )
}
