import Header from 'components/Header'
import Sidebar from 'components/Sidebar'

import { Box, Drawer, DrawerContent, useDisclosure } from '@chakra-ui/react'

export default function Layout({ children, user }) {
  const { isOpen, onOpen, onClose } = useDisclosure()
  return (
    <Box minH='100vh' bg='white'>
      <Sidebar
        onClose={() => onClose}
        display={{ base: 'none', md: 'block' }}
      />
      <Drawer
        autoFocus={false}
        isOpen={isOpen}
        placement='left'
        onClose={onClose}
        returnFocusOnClose={false}
        onOverlayClick={onClose}
        size='full'
      >
        <DrawerContent>
          <Sidebar onClose={onClose} />
        </DrawerContent>
      </Drawer>

      {/*= Header =*/}
      <Header onOpen={onOpen} user={user} />
      <Box ml={{ base: 0, md: '100px' }} p={2}>
        {children}
      </Box>
    </Box>
  )
}
