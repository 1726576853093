const courseForm = [
  {
    label: 'Course Name',
    name: 'name',
    type: 'text',
    placeholder: '',
    defaultValue: '',
    required: true,
  },
  {
    label: 'Description',
    name: 'description',
    type: 'textarea',
    placeholder: '',
    defaultValue: '',
    required: false,
  },
  {
    label: 'Type',
    name: 'type',
    type: 'dropdown',
    options: [
      'Occupational therapy (OT)',
      'Special education (SE)',
      'Speech therapy (ST)',
    ],
    placeholder: '',
    defaultValue: '',
    required: true,
  },
]

const programForm = [
  {
    label: 'Choice',
    name: 'choice',
    type: 'text',
    defaultValue: '',
    required: true,
  },
  {
    label: 'Amount',
    name: 'amount',
    type: 'number',
    defaultValue: '',
    required: true,
  },
  {
    label: 'Price',
    name: 'price',
    type: 'number',
    defaultValue: '',
    required: true,
  },
  {
    label: 'Color',
    name: 'color',
    type: 'color',
    defaultValue: '#FFF',
    required: true,
  },
]

export { courseForm, programForm }
