/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect, useRef } from 'react'
import {
  Center,
  Button,
  Box,
  HStack,
  Heading,
  SimpleGrid,
  useToast,
  Fade,
  useDisclosure,
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
  AlertDialogCloseButton,
  Spinner,
} from '@chakra-ui/react'
import { useForm } from 'react-hook-form'
import { Link, useParams } from 'react-router-dom'
import { db } from 'config/firebase'
import { onSnapshot, query, doc, setDoc } from 'firebase/firestore'

import FormInputs from 'components/DynamicForm'

const developmentForm = [
  {
    label: 'การวินิจฉัยโรค',
    name: 'diagnosis',
    type: 'text',
    placeholder: '',
    defaultValue: '',
  },
  {
    label: `แพทย์`,
    name: 'doctor',
    type: 'text',
    placeholder: '',
    defaultValue: '',
  },
  {
    label: `โรงพยาบาล`,
    name: 'hospital',
    type: 'text',
    placeholder: '',
    defaultValue: '',
  },
  {
    label: `อายุเมื่อทราบความผิดปกติ`,
    name: 'ageObserve',
    type: 'number',
    placeholder: '',
    defaultValue: '',
  },
  {
    label: `โรคประจำตัว`,
    name: 'congenitalDisease',
    type: 'text',
    placeholder: '',
    defaultValue: '',
  },
  {
    label: `อาหารที่แพ้`,
    name: 'allergicFood',
    type: 'text',
    placeholder: '',
    defaultValue: '',
  },
  {
    label: `ยาที่แพ้`,
    name: 'allergicDrug',
    type: 'text',
    placeholder: '',
    defaultValue: '',
  },
  {
    label: `มือข้างถนัด`,
    name: 'dominantHand',
    type: 'checkbox',
    options: ['ขวา', 'ซ้าย'],
    placeholder: '',
    defaultValue: [],
  },
  {
    label: `รูปแบบการรักษาที่เคยได้รับ/สถานที่`,
    name: 'treatment',
    type: 'textarea',
    placeholder: '',
    defaultValue: '',
  },

  {
    label: `พัฒนาการด้านที่ต้องการสร้างเสริม`,
    name: 'expectedDevelopment',
    type: 'textarea',
    placeholder: '',
    defaultValue: '',
  },
  {
    label: `พัฒนาการ`,
    name: 'development',
    type: 'textarea',
    placeholder: '',
    defaultValue: '',
  },
]

export default function KidForm() {
  const { id } = useParams()
  const toast = useToast()
  const [isLoading, setLoading] = useState(false)
  const [isLoadingData, setLoadingData] = useState(false)
  const [data, setdata] = useState()

  const { isOpen, onOpen, onClose } = useDisclosure()
  const cancelRef = useRef()

  const {
    handleSubmit,
    formState: { errors },
    control,
  } = useForm()

  useEffect(() => {
    if (id) {
      const queryKid = query(doc(db, 'Kids', id))
      const unsubscribe = onSnapshot(queryKid, (snapShot) => {
        setdata({
          ...snapShot.data(),
          id: snapShot.id,
        })

        setLoadingData(false)
      })
      return () => {
        unsubscribe()
      }
    }
  }, [id])

  function submit(values) {
    setLoading(true)
    if (data?.id) {
      updateKid(values)
    }
  }

  async function updateKid(values) {
    try {
      const kidRef = doc(db, 'Kids', `${data.id}`)

      await setDoc(
        kidRef,
        { development: { ...values, updatedAt: new Date() } },
        { merge: true }
      )

      setLoading(false)
      toast({
        position: 'top',
        title: 'Update Kid',
        description: 'Kid has been updated.',
        status: 'success',
        duration: 3000,
        isClosable: true,
      })
      //   navigate('/kids')
    } catch (e) {
      setLoading(false)
      toast({
        position: 'top',
        title: 'Update Fail.',
        description: e.message,
        status: 'error',
        duration: 3000,
        isClosable: true,
      })
    }
  }

  if (isLoadingData || !data) {
    return (
      <Center h='50vh'>
        <Spinner />
      </Center>
    )
  }

  return (
    <Fade initialscale={1.5} in={!isLoadingData}>
      <AlertDialog
        motionPreset='slideInBottom'
        leastDestructiveRef={cancelRef}
        onClose={onClose}
        isOpen={isOpen}
        isCentered
      >
        <AlertDialogOverlay />

        <AlertDialogContent>
          <AlertDialogHeader>Discard Changes?</AlertDialogHeader>
          <AlertDialogCloseButton />
          <AlertDialogBody>
            Are you sure you want to discard all of your input
          </AlertDialogBody>
          <AlertDialogFooter>
            <Button ref={cancelRef} onClick={onClose}>
              No
            </Button>
            <Link to={'/kids'}>
              <Button colorScheme='red' ml={3}>
                Yes
              </Button>
            </Link>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>
      <Box p={id ? 0 : 10}>
        <Center>
          <Heading my={10}>DEVELOPMENT</Heading>
        </Center>

        <Heading as='h5' size='sm' my={2} color='brand'>
          Development Information
        </Heading>
        <Box
          p={5}
          borderRadius={10}
          mb={10}
          borderWidth='1px'
          borderColor='brand'
        >
          <Box>
            <SimpleGrid
              columns={[1, 1, 2, 2]}
              spacingX={[1, 1, 1, '25px']}
              justifyContent='center'
              alignItems='center'
            >
              <FormInputs
                errors={errors}
                control={control}
                forms={developmentForm || []}
                data={data?.development}
              />
            </SimpleGrid>
          </Box>
        </Box>

        <HStack justifyContent='center' my={10}>
          <Button mr={3} variant='outline' onClick={onOpen}>
            Cancel
          </Button>
          <Button
            colorScheme='lime'
            bg='brand'
            onClick={handleSubmit(submit)}
            isLoading={isLoading}
          >
            Submit
          </Button>
        </HStack>
      </Box>
    </Fade>
  )
}
