import { useState, useEffect, useRef } from 'react'
import moment from 'moment'
import {
  Center,
  Button,
  Box,
  HStack,
  Heading,
  SimpleGrid,
  useToast,
  Spinner,
  useDisclosure,
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
  AlertDialogCloseButton,
  Flex,
  IconButton,
} from '@chakra-ui/react'
import { useForm } from 'react-hook-form'
import { useNavigate, useParams } from 'react-router-dom'
import { httpsCallable } from 'firebase/functions'
import { functions, db } from 'config/firebase'
import {
  onSnapshot,
  query,
  doc,
  updateDoc,
  collection,
  orderBy,
} from 'firebase/firestore'
import { BiArrowBack } from 'react-icons/bi'

import FormInputs from 'components/DynamicForm'
import {
  staffForm,
  workForm,
  workingDayForm,
  weekendForm,
  workDayCheckbox,
  weekEndCheckbox,
} from './fields'

const createAccount = httpsCallable(functions, 'createAccount')

export default function StaffForm() {
  const navigate = useNavigate()
  const { id } = useParams()
  const toast = useToast()
  const [isLoading, setLoading] = useState(false)
  const [isLoadingData, setLoadingData] = useState(false)
  const [data, setdata] = useState()
  const [branches, setBranches] = useState()
  const { isOpen, onOpen, onClose } = useDisclosure()
  const cancelRef = useRef()

  useEffect(() => {
    const queryBranches = query(
      collection(db, 'Branches'),
      orderBy('createdAt', 'desc')
    )
    const unsubscribe = onSnapshot(queryBranches, (snapShot) => {
      let data = [
        {
          label: 'Select Branch',
          name: 'branch',
          type: 'dropdown',
          options: [],
          placeholder: '',
          defaultValue: '',
          required: true,
        },
      ]
      snapShot.forEach((doc) => {
        data[0].options.push(doc.data().branchName)
      })
      setBranches(data)
    })
    return () => {
      console.log('unmount')
      unsubscribe()
    }
  }, [])

  useEffect(() => {
    if (id) {
      const queryStaff = query(doc(db, 'Profiles', id))
      const unsubscribe = onSnapshot(queryStaff, (snapShot) => {
        setdata({
          ...snapShot.data(),
          startLunchWeekend: new Date(snapShot.data().startLunchWeekend),
          startLunchWorkday: new Date(snapShot.data().startLunchWorkday),
          startTimeWeekend: new Date(snapShot.data().startTimeWeekend),
          startTimeWorkday: new Date(snapShot.data().startTimeWorkday),
          endLunchWeekend: new Date(snapShot.data().endLunchWeekend),
          endLunchWorkday: new Date(snapShot.data().endLunchWorkday),
          endTimeWeekend: new Date(snapShot.data().endTimeWeekend),
          endTimeWorkday: new Date(snapShot.data().endTimeWorkday),
          id: snapShot.id,
        })
        setLoadingData(false)
      })
      return () => {
        unsubscribe()
      }
    }
  }, [id])

  const {
    handleSubmit,
    formState: { errors },
    control,
    resetField,
  } = useForm()

  function submit(values) {
    if (data?.id) {
      updateStaff(values)
    } else {
      createStaffAccount(values)
    }
  }

  function createStaffAccount(values) {
    setLoading(true)
    createAccount({
      ...values,
      startLunchWeekend: new Date(values.startLunchWeekend),
      startLunchWorkday: new Date(values.startLunchWorkday),
      startTimeWeekend: new Date(values.startTimeWeekend),
      startTimeWorkday: new Date(values.startTimeWorkday),
      endLunchWeekend: new Date(values.endLunchWeekend),
      endLunchWorkday: new Date(values.endLunchWorkday),
      endTimeWeekend: new Date(values.endTimeWeekend),
      endTimeWorkday: new Date(values.endTimeWorkday),
      role: 'Staff',
    })
      .then(() => {
        toast({
          position: 'top',
          title: 'Create Account',
          description: 'Staff has been created.',
          status: 'success',
          duration: 3000,
          isClosable: true,
        })
        setLoading(false)
        resetField()
        navigate('/settings/staff')
      })
      .catch((e) => {
        setLoading(false)
        toast({
          position: 'top',
          title: 'Create Fail.',
          description: e.message,
          status: 'error',
          duration: 3000,
          isClosable: true,
        })
      })
  }

  function updateStaff(values) {
    setLoading(true)

    updateDoc(doc(db, 'Profiles', data.id), {
      ...values,
      startLunchWeekend: moment(values.startLunchWeekend).format(
        'YYYY-MM-DDTHH:mm:ss.SSSSZ'
      ),
      startLunchWorkday: moment(values.startLunchWorkday).format(
        'YYYY-MM-DDTHH:mm:ss.SSSSZ'
      ),
      startTimeWeekend: moment(values.startTimeWeekend).format(
        'YYYY-MM-DDTHH:mm:ss.SSSSZ'
      ),
      startTimeWorkday: moment(values.startTimeWorkday).format(
        'YYYY-MM-DDTHH:mm:ss.SSSSZ'
      ),
      endLunchWeekend: moment(values.endLunchWeekend).format(
        'YYYY-MM-DDTHH:mm:ss.SSSSZ'
      ),
      endLunchWorkday: moment(values.endLunchWorkday).format(
        'YYYY-MM-DDTHH:mm:ss.SSSSZ'
      ),
      endTimeWeekend: moment(values.endTimeWeekend).format(
        'YYYY-MM-DDTHH:mm:ss.SSSSZ'
      ),
      endTimeWorkday: moment(values.endTimeWorkday).format(
        'YYYY-MM-DDTHH:mm:ss.SSSSZ'
      ),
    })
      .then(() => {
        toast({
          position: 'top',
          title: 'Update Staff',
          description: 'Staff has been updated.',
          status: 'success',
          duration: 3000,
          isClosable: true,
        })
        setLoading(false)
        resetField()
        navigate('/settings/staff')
      })
      .catch((e) => {
        toast({
          position: 'top',
          title: 'Update Fail.',
          description: e.message,
          status: 'error',
          duration: 3000,
          isClosable: true,
        })
        setLoading(false)
      })
  }
  if (isLoadingData || (id && !data)) {
    return (
      <Center>
        <Spinner />
      </Center>
    )
  }

  return (
    <Box p={10}>
      <AlertDialog
        motionPreset='slideInBottom'
        leastDestructiveRef={cancelRef}
        onClose={onClose}
        isOpen={isOpen}
        isCentered
      >
        <AlertDialogOverlay />

        <AlertDialogContent>
          <AlertDialogHeader>Discard Changes?</AlertDialogHeader>
          <AlertDialogCloseButton />
          <AlertDialogBody>
            Are you sure you want to discard all of your input
          </AlertDialogBody>
          <AlertDialogFooter>
            <Button ref={cancelRef} onClick={onClose}>
              No
            </Button>

            <Button
              colorScheme='red'
              ml={3}
              onClick={() => navigate('/settings/staff')}
            >
              Yes
            </Button>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>
      <Flex justifyContent='flex-start'>
        <IconButton
          size={30}
          variant='ghost'
          color='brand'
          icon={<BiArrowBack size={30} />}
          onClick={() => navigate('/settings/staff')}
        />
      </Flex>
      <Center>
        <Heading my={10}>{`${id ? 'EDIT' : 'CREATE'} STAFF`}</Heading>
      </Center>
      <Heading as='h5' size='sm' my={2} color='brand'>
        Staff Information
      </Heading>
      <Box
        p={5}
        border='1px solid brand'
        borderRadius={10}
        mb={5}
        borderWidth='1px'
        borderColor='brand'
      >
        <Box mx={{ base: 0, md: 20, lg: 40, xl: 60 }}>
          <FormInputs
            errors={errors}
            control={control}
            forms={staffForm || []}
            data={data}
          />
        </Box>
      </Box>
      <Heading as='h5' size='sm' my={2} color='brand'>
        Work Information
      </Heading>
      <Box
        p={5}
        border='1px solid brand'
        borderRadius={10}
        borderWidth='1px'
        borderColor='brand'
      >
        <Box mx={{ base: 0, md: 20, lg: 40, xl: 60 }}>
          <FormInputs
            errors={errors}
            control={control}
            forms={branches || []}
            data={data}
          />
          <FormInputs
            errors={errors}
            control={control}
            forms={workForm || []}
            data={data}
          />
        </Box>
        <Box mx={{ base: 0, md: 20, lg: 40, xl: 60 }}>
          <FormInputs
            errors={errors}
            control={control}
            forms={workDayCheckbox || []}
            data={data}
          />
        </Box>

        <SimpleGrid
          mx={{ base: 0, md: 20, lg: 40, xl: 60 }}
          columns={[1, 2]}
          spacingX='40px'
        >
          <FormInputs
            errors={errors}
            control={control}
            forms={workingDayForm || []}
            data={data}
          />
        </SimpleGrid>

        <Box mx={{ base: 0, md: 20, lg: 40, xl: 60 }}>
          <FormInputs
            errors={errors}
            control={control}
            forms={weekEndCheckbox || []}
            data={data}
          />
        </Box>

        <SimpleGrid
          mx={{ base: 0, md: 20, lg: 40, xl: 60 }}
          columns={[1, 2]}
          spacingX='40px'
        >
          <FormInputs
            errors={errors}
            control={control}
            forms={weekendForm || []}
            data={data}
          />
        </SimpleGrid>
      </Box>

      <HStack justifyContent='center' my={10}>
        <Button mr={3} variant='outline' onClick={onOpen}>
          Cancel
        </Button>
        <Button
          colorScheme='lime'
          onClick={handleSubmit(submit)}
          bg='brand'
          isLoading={isLoading}
        >
          Submit
        </Button>
      </HStack>
    </Box>
  )
}
