/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect, useMemo } from 'react'
import {
  Box,
  Flex,
  Heading,
  VStack,
  HStack,
  Button,
  IconButton,
  Input,
  InputGroup,
  useToast,
} from '@chakra-ui/react'
import { Link, useNavigate } from 'react-router-dom'
import {
  onSnapshot,
  query,
  collection,
  where,
  orderBy,
} from 'firebase/firestore'
import { Table, Popconfirm } from 'antd'
import { search } from 'ss-search'
import { db, functions } from 'config/firebase'
import { httpsCallable } from 'firebase/functions'
import { BiArrowBack } from 'react-icons/bi'

import { AiFillEdit, AiFillDelete } from 'react-icons/ai'

const deleteAccount = httpsCallable(functions, 'deleteAccount')

export default function Staff() {
  const [allStaff, setAllStaff] = useState([])
  const [staffQuery, setStaffQuery] = useState([])
  const [loadAllStaff, setLoadAllStaff] = useState(true)
  const toast = useToast()
  const navigate = useNavigate()

  useEffect(() => {
    const queryStaff = query(
      collection(db, 'Profiles'),
      where('role', '==', 'Staff'),
      orderBy('createdAt', 'desc')
    )
    const unsubscribe = onSnapshot(queryStaff, (snapShot) => {
      let data = []
      snapShot.forEach((doc) => {
        data.push({ ...doc.data(), id: doc.id })
      })
      setAllStaff(data)
      setStaffQuery(data)
      setLoadAllStaff(false)
    })
    return () => {
      console.log('unmount')
      unsubscribe()
    }
  }, [])

  function handleDelete(id) {
    deleteAccount({ uid: id })
      .then(() => {
        toast({
          position: 'top',
          title: 'Delete Staff',
          description: 'Staff has been Deleted.',
          status: 'success',
          duration: 3000,
          isClosable: true,
        })
      })
      .catch((e) => {
        toast({
          position: 'top',
          title: 'Delete Fail.',
          description: e.message,
          status: 'error',
          duration: 3000,
          isClosable: true,
        })
      })
  }

  const columns = useMemo(() => {
    return [
      {
        title: 'Email',
        dataIndex: 'email',
        key: 'email',
      },
      {
        title: 'Full Name',
        dataIndex: 'fullname',
        key: 'fullname',
        width: '20%',
      },
      {
        title: 'Nick Name',
        dataIndex: 'nickname',
        key: 'nickname',
        width: '20%',
      },
      {
        title: 'Position',
        dataIndex: 'position',
        key: 'position',
        width: '20%',
      },
      {
        title: 'Office Branch',
        dataIndex: 'branch',
        key: 'branch',
        width: '20%',
      },
      {
        title: 'Action',
        dataIndex: 'Action',
        width: '10%',
        key: 'Action',
        render: (data, row) => {
          return (
            <HStack justifyContent='space-between'>
              <HStack spacing={2}>
                <Link to={`/settings/staff/edit/${row.id}`}>
                  <IconButton
                    variant='ghost'
                    aria-label='edit'
                    icon={<AiFillEdit color='brand' fontSize='25px' />}
                  />
                </Link>
                <Popconfirm
                  title='Sure to delete this Staff'
                  onConfirm={() => {
                    handleDelete(row.id)
                  }}
                >
                  <IconButton
                    variant='ghost'
                    aria-label='delete'
                    icon={<AiFillDelete color='red' fontSize='25px' />}
                  />
                </Popconfirm>
              </HStack>
            </HStack>
          )
        },
      },
    ]
  }, [])

  return (
    <Box p={{ base: 2, lg: 10 }}>
      <Flex justifyContent='flex-start'>
        <IconButton
          size={30}
          variant='ghost'
          color='brand'
          icon={<BiArrowBack size={30} />}
          onClick={() => navigate('/settings')}
        />
      </Flex>
      <VStack spacing={10} mb='10'>
        <Heading>STAFF</Heading>
        <Flex justifyContent='flex-end' w={'100%'}>
          <Link to={'/settings/staff/create'}>
            <Button variant='solid' colorScheme='orange' bg='#F6872B'>
              Create Staff
            </Button>
          </Link>
        </Flex>
        <Flex justifyContent='flex-start' w={'100%'}>
          <InputGroup size='lg' maxW={350}>
            <Input
              pr='4.5rem'
              onChange={(e) => {
                let textSearch = e.currentTarget.value
                const searchKeys = ['nickname', 'fullname', 'email']
                const results = search(allStaff, searchKeys, textSearch)
                const searchResults = textSearch === '' ? allStaff : results
                setStaffQuery(searchResults)
              }}
              placeholder='Search by Name, Email'
            />
          </InputGroup>
        </Flex>
      </VStack>
      <Table
        bordered
        rowClassName={() => 'rowClassName1'}
        size='small'
        dataSource={staffQuery}
        columns={columns}
        loading={loadAllStaff}
        rowKey='id'
        scroll={{ x: 'max-content' }}
      />
    </Box>
  )
}
