import { useState, useEffect } from 'react'
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  useToast,
  FormControl,
  FormLabel,
  FormErrorMessage,
  Box,
  Text,
} from '@chakra-ui/react'
import Select from 'react-select'
import { useForm, Controller } from 'react-hook-form'
import { db } from 'config/firebase'
import { addDoc, collection, query, onSnapshot } from 'firebase/firestore'

const groupStyles = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
}
const groupBadgeStyles = {
  backgroundColor: '#EBECF0',
  borderRadius: '2em',
  color: '#172B4D',
  display: 'inline-block',
  fontSize: 12,
  fontWeight: 'normal',
  lineHeight: '1',
  minWidth: 1,
  padding: '0.16666666666667em 0.5em',
  textAlign: 'center',
}

const formatGroupLabel = (data) => (
  <Box {...groupStyles}>
    <Text fontSize='lg'>Course : {data.name}</Text>
    <span style={groupBadgeStyles}>{data.programs.length}</span>
  </Box>
)

export default function CourseModal({ isOpen, onClose, kidId }) {
  const [isLoading, setLoading] = useState(false)
  const toast = useToast()
  const [courses, setCourseList] = useState([])

  const {
    handleSubmit,
    formState: { errors },
    control,
  } = useForm()

  useEffect(() => {
    const queryBranches = query(collection(db, 'Courses'))
    const unsubscribe = onSnapshot(queryBranches, (snapShot) => {
      let data = []
      snapShot.forEach((doc) => {
        const options = doc.data().programs.map((program) => ({
          value: {
            type: doc.data().type,
            name: doc.data().name,
            description: doc.data().description,
            ...program,
          },
          label: `${program.choice} (${program.amount} ${program.unit})`,
        }))
        data.push({ ...doc.data(), id: doc.id, options })
      })

      setCourseList(data)
    })
    return () => {
      unsubscribe()
    }
  }, [])

  function create(data) {
    setLoading(true)
    addDoc(collection(db, `Kids/${kidId}/Programs`), {
      ...data.course.value,
      takedCourse: new Array(parseInt(data.course.value.amount)).fill({
        isTaked: false,
        date: '',
        time: '',
        staff: '',
      }),
      createdAt: new Date(),
    })
      .then(() => {
        toast({
          position: 'top',
          title: 'Add Kid Program',
          description: 'Kid Program has been created.',
          status: 'success',
          duration: 3000,
          isClosable: true,
        })
        setLoading(false)
        onClose()
      })
      .catch((e) => {
        setLoading(false)
        toast({
          position: 'top',
          title: 'Add Fail.',
          description: e.message,
          status: 'error',
          duration: 3000,
          isClosable: true,
        })
      })
  }

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      size={'lg'}
      closeOnOverlayClick={false}
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader textAlign='center'>Add Course</ModalHeader>
        <ModalCloseButton />
        <ModalBody p={10}>
          <FormControl
            my={3}
            id='course'
            name='course'
            isInvalid={true}
            w={'100%'}
          >
            <FormLabel>Course</FormLabel>
            <Controller
              name='course'
              control={control}
              rules={{
                required: 'Please select a course',
              }}
              defaultValue={''}
              render={({ field: { onChange, value } }) => (
                <Select
                  id='course'
                  name='course'
                  placeholder='Select course'
                  options={courses}
                  formatGroupLabel={formatGroupLabel}
                  onChange={onChange}
                  value={value}
                />
              )}
            />
            <FormErrorMessage>
              {errors.course && errors.course.message}
            </FormErrorMessage>
          </FormControl>
        </ModalBody>

        <ModalFooter justifyContent='center'>
          <Button mr={3} variant='outline' onClick={onClose}>
            Close
          </Button>
          <Button
            colorScheme='lime'
            onClick={handleSubmit(create)}
            bg='brand'
            isLoading={isLoading}
          >
            Submit
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}
