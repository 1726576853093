import { useState, useEffect, useMemo } from 'react'
import {
  Flex,
  Heading,
  VStack,
  HStack,
  Button,
  Text,
  IconButton,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  useDisclosure,
  useToast,
} from '@chakra-ui/react'
import { Table, Popconfirm } from 'antd'
import {
  onSnapshot,
  query,
  collection,
  deleteDoc,
  orderBy,
  doc,
} from 'firebase/firestore'
import { AiFillEdit, AiFillDelete, AiFillCaretDown } from 'react-icons/ai'

import { db } from 'config/firebase'

import BankModal from './Modal/bank'
import CashModal from './Modal/cash'

export default function BracnhPayment() {
  const { isOpen, onOpen, onClose } = useDisclosure()
  const {
    isOpen: isOpenCash,
    onOpen: onOpenCash,
    onClose: onCloseCash,
  } = useDisclosure()
  const [cash, setCash] = useState()
  const [bank, setBank] = useState()
  const toast = useToast()

  const [paymentQuery, setPaymentQuery] = useState([])
  const [loadAllPayment, setLoadAllPayment] = useState(true)

  useEffect(() => {
    const queryPayment = query(
      collection(db, 'PaymentTypes'),
      orderBy('createdAt', 'desc')
    )
    const unsubscribe = onSnapshot(queryPayment, (snapShot) => {
      let data = []
      snapShot.forEach((doc) => {
        data.push({ ...doc.data(), id: doc.id })
      })
      setPaymentQuery(data)
      setLoadAllPayment(false)
    })
    return () => {
      unsubscribe()
    }
  }, [])

  function handleDelete(id) {
    deleteDoc(doc(db, 'PaymentTypes', id))
      .then(() => {
        toast({
          position: 'top',
          title: 'Delete Payment',
          description: 'Payment has been Deleted.',
          status: 'success',
          duration: 3000,
          isClosable: true,
        })
      })
      .catch((e) => {
        toast({
          position: 'top',
          title: 'Delete Fail.',
          description: e.message,
          status: 'error',
          duration: 3000,
          isClosable: true,
        })
      })
  }

  const columns = useMemo(() => {
    return [
      {
        title: 'ช่องทางชำระเงิน',
        dataIndex: 'type',
        key: 'type',
        width: '20%',
        render: (data, row) => {
          return data === 'bank' ? 'บัญชีธนาคาร' : 'เงินสด'
        },
      },
      {
        title: 'ชื่อบัญชี',
        dataIndex: 'name',
        key: 'name',
        width: '20%',
        render: (data, row) => {
          if (row?.type === 'bank') {
            return (
              <VStack align='start'>
                <Text>{`${row?.bank} ${row?.accountNo}`}</Text>
                <Text>{`${row?.accountName}`}</Text>
              </VStack>
            )
          } else {
            return row.name
          }
        },
      },
      {
        title: 'รายละเอียดเพิ่มเติม',
        dataIndex: 'description',
        key: 'description',
        width: '20%',
        render: (data, row) => {
          if (row?.type === 'bank') {
            return (
              <VStack align='start'>
                <Text>{`ประเภทบัญชี : ${row?.accountType}`}</Text>
                <Text>{`สาขา : ${row?.branch}`}</Text>
              </VStack>
            )
          } else {
            return data
          }
        },
      },
      {
        title: 'Action',
        dataIndex: 'Action',
        key: 'Action',
        width: '10%',
        render: (data, row) => {
          return (
            <HStack justifyContent='space-between'>
              <HStack spacing={2}>
                <IconButton
                  variant='ghost'
                  aria-label='edit'
                  icon={<AiFillEdit color='brand' fontSize='25px' />}
                  onClick={() => {
                    if (row.type === 'bank') {
                      setBank(row)
                      onOpen()
                    } else {
                      setCash(row)
                      onOpenCash()
                    }
                  }}
                />
                <Popconfirm
                  title='Sure to delete this Payment'
                  onConfirm={() => {
                    handleDelete(row.id)
                  }}
                >
                  <IconButton
                    variant='ghost'
                    aria-label='delete'
                    icon={<AiFillDelete color='red' fontSize='25px' />}
                  />
                </Popconfirm>
              </HStack>
            </HStack>
          )
        },
      },
    ]
    // eslint-disable-next-line
  }, [])

  return (
    <>
      <VStack spacing={10} mb='10'>
        {isOpen && <BankModal isOpen={isOpen} onClose={onClose} data={bank} />}
        {isOpenCash && (
          <CashModal isOpen={isOpenCash} onClose={onCloseCash} data={cash} />
        )}

        <Heading>PAYMENTS</Heading>
        <Flex justifyContent='flex-end' w={'100%'}>
          <Menu>
            <MenuButton
              as={Button}
              rightIcon={<AiFillCaretDown />}
              variant='solid'
              colorScheme='orange'
              bg='#F6872B'
            >
              Create Payment
            </MenuButton>
            <MenuList justifyContent='center' alignItems='center'>
              <MenuItem textAlign='center' onClick={onOpen}>
                บัญชีธนาคาร
              </MenuItem>
              <MenuItem textAlign='center' onClick={onOpenCash}>
                เงินสด
              </MenuItem>
            </MenuList>
          </Menu>
        </Flex>
      </VStack>
      <Table
        bordered
        rowClassName={() => 'rowClassName1'}
        size='small'
        dataSource={paymentQuery}
        columns={columns}
        loading={loadAllPayment}
        rowKey='id'
        scroll={{ x: 'max-content' }}
      />
    </>
  )
}
