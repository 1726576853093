const staffForm = [
  {
    label: 'Full Name',
    name: 'fullname',
    type: 'text',
    placeholder: '',
    defaultValue: '',
    required: true,
  },
  {
    label: 'Nick Name',
    name: 'nickname',
    type: 'text',
    placeholder: '',
    defaultValue: '',
    required: true,
  },
  {
    label: 'Telephone Number',
    name: 'tel',
    type: 'text',
    placeholder: '',
    defaultValue: '',
    required: true,
  },
  {
    label: 'Email',
    name: 'email',
    type: 'text',
    placeholder: '',
    defaultValue: '',
    required: true,
    hideOnEdit: true,
  },
  {
    label: 'Password',
    name: 'password',
    type: 'password',
    placeholder: '',
    defaultValue: '',
    required: true,
    hideOnEdit: true,
  },
]

const workForm = [
  {
    label: 'Position',
    name: 'position',
    type: 'radio',
    placeholder: '',
    options: [
      'Occupational therapy (OT)',
      'Special education (SE)',
      'Speech therapy (ST)',
    ],
    defaultValue: '',
    required: true,
  },
]

const workDayCheckbox = [
  {
    label: 'Working Day',
    name: 'workingDay',
    type: 'checkbox',
    placeholder: '',
    options: ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday'],
    defaultValue: ['Tuesday', 'Wednesday', 'Thursday', 'Friday'],
    required: true,
  },
]

const weekEndCheckbox = [
  {
    label: 'Working Day',
    name: 'workingDayWeekend',
    type: 'checkbox',
    placeholder: '',
    options: ['Saturday', 'Sunday'],
    defaultValue: ['Saturday', 'Sunday'],
    required: true,
  },
]

const workingDayForm = [
  {
    label: 'Start Time',
    name: 'startTimeWorkday',
    type: 'time',
    placeholder: '',
    defaultValue: '13:00',
    required: true,
  },
  {
    label: 'End Time',
    name: 'endTimeWorkday',
    type: 'time',
    placeholder: '',
    defaultValue: '19:30',
    required: true,
  },
  {
    label: 'Start Lunch',
    name: 'startLunchWorkday',
    type: 'time',
    placeholder: '',
    defaultValue: '',
    // required: true,
  },
  {
    label: 'End Lunch',
    name: 'endLunchWorkday',
    type: 'time',
    placeholder: '',
    defaultValue: '',
    // required: true,
  },
]

const weekendForm = [
  {
    label: 'Start Time Weekend',
    name: 'startTimeWeekend',
    type: 'time',
    placeholder: '',
    defaultValue: '09:00',
    required: true,
  },
  {
    label: 'End Time Weekend',
    name: 'endTimeWeekend',
    type: 'time',
    placeholder: '',
    defaultValue: '18:00',
    required: true,
  },
  {
    label: 'Start Lunch Weekend',
    name: 'startLunchWeekend',
    type: 'time',
    placeholder: '',
    defaultValue: '',
    // required: true,
  },
  {
    label: 'End Lunch Weekend',
    name: 'endLunchWeekend',
    type: 'time',
    placeholder: '',
    defaultValue: '',
    // required: true,
  },
]

export {
  staffForm,
  workForm,
  workingDayForm,
  weekendForm,
  workDayCheckbox,
  weekEndCheckbox,
}
