import { useState, useEffect, useMemo } from 'react'
import {
  Flex,
  Heading,
  VStack,
  HStack,
  Button,
  IconButton,
  useDisclosure,
  useToast,
} from '@chakra-ui/react'
import {
  onSnapshot,
  query,
  collection,
  deleteDoc,
  orderBy,
  doc,
} from 'firebase/firestore'
import { Table, Popconfirm } from 'antd'
import { db } from 'config/firebase'
import { AiFillEdit, AiFillDelete } from 'react-icons/ai'

import BranchModal from './Modal/branch'

export default function BracnhInfo() {
  const toast = useToast()
  const { isOpen, onOpen, onClose } = useDisclosure()
  const [editData, setEdit] = useState(null)
  const [branchQuery, setBranchQuery] = useState([])
  const [loadAllBranch, setLoadAllBranch] = useState(true)
  useEffect(() => {
    const queryBranch = query(
      collection(db, 'Branches'),
      orderBy('createdAt', 'desc')
    )
    const unsubscribe = onSnapshot(queryBranch, (snapShot) => {
      let data = []
      snapShot.forEach((doc) => {
        data.push({ ...doc.data(), id: doc.id })
      })
      setBranchQuery(data)
      setLoadAllBranch(false)
    })
    return () => {
      console.log('unmount')
      unsubscribe()
    }
  }, [])

  const columns = useMemo(() => {
    return [
      {
        title: 'Branch Code',
        dataIndex: 'branchCode',
        key: 'branchCode',
        width: '20%',
      },
      {
        title: 'Branch Name',
        dataIndex: 'branchName',
        key: 'branchName',
        width: '20%',
      },
      {
        title: 'Action',
        dataIndex: 'Action',
        key: 'Action',
        width: '10%',
        render: (data, row) => {
          return (
            <HStack justifyContent='space-between'>
              <HStack spacing={2}>
                <IconButton
                  variant='ghost'
                  aria-label='edit'
                  icon={<AiFillEdit color='brand' fontSize='25px' />}
                  onClick={() => {
                    setEdit(row)
                    onOpen()
                  }}
                />
                <Popconfirm
                  title='Sure to delete this Branch'
                  onConfirm={() => {
                    handleDelete(row.id)
                  }}
                >
                  <IconButton
                    variant='ghost'
                    aria-label='delete'
                    icon={<AiFillDelete color='red' fontSize='25px' />}
                  />
                </Popconfirm>
              </HStack>
            </HStack>
          )
        },
      },
    ]
    // eslint-disable-next-line
  }, [])

  function handleDelete(id) {
    deleteDoc(doc(db, 'Branches', id))
      .then(() => {
        toast({
          position: 'top',
          title: 'Delete Branch',
          description: 'Branch has been Deleted.',
          status: 'success',
          duration: 3000,
          isClosable: true,
        })
      })
      .catch((e) => {
        toast({
          position: 'top',
          title: 'Delete Fail.',
          description: e.message,
          status: 'error',
          duration: 3000,
          isClosable: true,
        })
      })
  }

  return (
    <>
      <VStack spacing={10} mb='10'>
        {isOpen && (
          <BranchModal
            isOpen={isOpen}
            onClose={() => {
              setEdit(null)
              onClose()
            }}
            data={editData}
          />
        )}
        <Heading>BRANCHES</Heading>
        <Flex justifyContent='flex-end' w={'100%'}>
          <Button
            variant='solid'
            colorScheme='orange'
            bg='#F6872B'
            onClick={onOpen}
          >
            Create Branch
          </Button>
        </Flex>
      </VStack>
      <Table
        bordered
        rowClassName={() => 'rowClassName1'}
        size='small'
        dataSource={branchQuery}
        columns={columns}
        loading={loadAllBranch}
        rowKey='id'
        scroll={{ x: 'max-content' }}
      />
    </>
  )
}
