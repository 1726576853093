import { useState } from 'react'
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  useToast,
} from '@chakra-ui/react'
import { useForm } from 'react-hook-form'
import { httpsCallable } from 'firebase/functions'
import { functions, db } from 'config/firebase'
import { doc, updateDoc } from 'firebase/firestore'

import FormInputs from 'components/DynamicForm'
import { adminForm as form } from '../fields'

const createAccount = httpsCallable(functions, 'createAccount')

export default function AdminModal({ isOpen, onClose, data, branches }) {
  const [isLoading, setLoading] = useState(false)
  const toast = useToast()

  const {
    handleSubmit,
    formState: { errors },
    control,
    resetField,
  } = useForm()

  function submit(values) {
    if (data?.id) {
      updateAdmin(values)
    } else {
      createAdminAccount(values)
    }
  }

  function createAdminAccount(values) {
    setLoading(true)
    createAccount({ ...values, role: 'Admin' })
      .then(() => {
        toast({
          position: 'top',
          title: 'Create Account',
          description: 'Admin has been created.',
          status: 'success',
          duration: 3000,
          isClosable: true,
        })
        setLoading(false)
        resetField()
        onClose()
      })
      .catch((e) => {
        setLoading(false)
        toast({
          position: 'top',
          title: 'Create Fail.',
          description: e.message,
          status: 'error',
          duration: 3000,
          isClosable: true,
        })
      })
  }

  function updateAdmin(values) {
    setLoading(true)
    updateDoc(doc(db, 'Profiles', data.id), { ...values })
      .then(() => {
        toast({
          position: 'top',
          title: 'Update Admin',
          description: 'Admin has been updated.',
          status: 'success',
          duration: 3000,
          isClosable: true,
        })
        setLoading(false)
        resetField()
        onClose()
      })
      .catch((e) => {
        toast({
          position: 'top',
          title: 'Update Fail.',
          description: e.message,
          status: 'error',
          duration: 3000,
          isClosable: true,
        })
        setLoading(false)
      })
  }

  return (
    <>
      <Modal
        isOpen={isOpen}
        onClose={onClose}
        size={'lg'}
        closeOnOverlayClick={false}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader textAlign='center'>
            {data?.id ? 'EDIT' : 'CREATE'} ADMIN
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody p={10}>
            <FormInputs
              errors={errors}
              control={control}
              forms={form || []}
              data={data}
            />
            <FormInputs
              errors={errors}
              control={control}
              forms={branches || []}
              data={data}
            />
          </ModalBody>

          <ModalFooter justifyContent='center'>
            <Button mr={3} variant='outline' onClick={onClose}>
              Close
            </Button>
            <Button
              colorScheme='lime'
              onClick={handleSubmit(submit)}
              bg='brand'
              isLoading={isLoading}
              loadingText='Submitting'
            >
              Submit
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  )
}
