import { useState } from 'react'
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  useToast,
  Switch,
  FormControl,
  FormLabel,
  HStack,
  Text,
} from '@chakra-ui/react'
import { useForm } from 'react-hook-form'
import { db } from 'config/firebase'
import { doc, updateDoc, addDoc, collection } from 'firebase/firestore'

import FormInputs from 'components/DynamicForm'

const activeForm = [
  {
    label: `1st Treatment Date`,
    name: 'activeDate',
    type: 'date',
    placeholder: '',
    defaultValue: '',
    required: true,
  },
  {
    label: `Member Expire Date`,
    name: 'expireDate',
    type: 'date',
    placeholder: '',
    defaultValue: '',
    required: true,
  },
  {
    label: `Member Fee`,
    name: 'fee',
    type: 'number',
    placeholder: '',
    defaultValue: '',
    required: true,
  },
  {
    label: `Member Fee Paid Date`,
    name: 'feePaidDate',
    type: 'date',
    placeholder: '',
    defaultValue: '',
  },
]

const offForm = [
  {
    label: `Off/Discharge Date`,
    name: 'offDate',
    type: 'date',
    placeholder: '',
    defaultValue: '',
  },
  {
    label: `Reason`,
    name: 'reason',
    type: 'textarea',
    placeholder: '',
    defaultValue: '',
  },
  {
    label: `Return Date`,
    name: 'returnDate',
    type: 'date',
    placeholder: '',
    defaultValue: '',
  },
]

export default function ActiveModal({ isOpen, onClose, data, kidId }) {
  const [isLoading, setLoading] = useState(false)
  const [status, setStatus] = useState(data?.status || 'member')
  const toast = useToast()
  const {
    handleSubmit,
    formState: { errors },
    control,
    resetField,
  } = useForm()

  function submit(values) {
    if (data?.id) {
      update(values)
    } else {
      create(values)
    }
  }
  function create(values) {
    setLoading(true)
    addDoc(collection(db, `Kids/${kidId}/Status`), {
      ...values,
      createdAt: new Date(),
      status: status,
    })
      .then(() => updateDoc(doc(db, `Kids`, kidId), { status: status }))
      .then(() => {
        toast({
          position: 'top',
          title: 'Add Kid Status',
          description: 'Kid Status has been created.',
          status: 'success',
          duration: 3000,
          isClosable: true,
        })
        setLoading(false)
        resetField()
        onClose()
      })
      .catch((e) => {
        setLoading(false)
        toast({
          position: 'top',
          title: 'Add Fail.',
          description: e.message,
          status: 'error',
          duration: 3000,
          isClosable: true,
        })
      })
  }

  function update(values) {
    setLoading(true)
    updateDoc(doc(db, `Kids/${kidId}/Status`, data.id), {
      ...values,
      status: status,
      updatedAt: new Date(),
    })
      .then(() => updateDoc(doc(db, `Kids`, kidId), { status: status }))
      .then(() => {
        toast({
          position: 'top',
          title: 'Update Kid Status',
          description: 'Kid Status has been updated.',
          status: 'success',
          duration: 3000,
          isClosable: true,
        })
        setLoading(false)
        resetField()
        onClose()
      })
      .catch((e) => {
        toast({
          position: 'top',
          title: 'Update Fail.',
          description: e.message,
          status: 'error',
          duration: 3000,
          isClosable: true,
        })
        setLoading(false)
      })
  }

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      size={'lg'}
      closeOnOverlayClick={false}
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader textAlign='center'>Kid Status</ModalHeader>
        <ModalCloseButton />
        <ModalBody p={10}>
          <FormInputs
            errors={errors}
            control={control}
            forms={activeForm || []}
            data={data}
          />
          <FormControl alignItems='center' my={3}>
            <FormLabel htmlFor='status'>Kid Status</FormLabel>
            <HStack spacing={4}>
              <Text color={'#767262'}>{`Off`}</Text>
              <Switch
                id='status'
                size='md'
                colorScheme='green'
                defaultChecked={status === 'member'}
                onChange={(e) => setStatus(e.target.checked ? 'member' : 'off')}
              />
              <Text color={'green'}>{`Active`}</Text>
            </HStack>
          </FormControl>
          <FormInputs
            errors={errors}
            control={control}
            forms={offForm || []}
            data={data}
          />
        </ModalBody>

        <ModalFooter justifyContent='center'>
          <Button mr={3} variant='outline' onClick={onClose}>
            Close
          </Button>
          <Button
            colorScheme='lime'
            onClick={handleSubmit(submit)}
            bg='brand'
            isLoading={isLoading}
          >
            Submit
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}
