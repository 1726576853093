import { useState } from 'react'
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
} from '@chakra-ui/react'
import { useForm } from 'react-hook-form'

import FormInputs from 'components/DynamicForm'

export default function StatusModal({ isOpen, onClose, data, onSubmit, user }) {
  const [isLoading, setLoading] = useState(false)
  const paymentStatusForm = [
    {
      label: 'วันที่ชำระ',
      name: 'createdDate',
      type: 'date',
      placeholder: '',
      defaultValue: '',
      required: true,
    },
    {
      label: `เลขที่การชำระเงิน`,
      name: 'paymentNo',
      type: 'text',
      placeholder: '',
      defaultValue: '',
      required: true,
    },
    {
      label: `สร้างโดย`,
      name: 'createdBy',
      type: 'text',
      placeholder: '',
      defaultValue: '',
      required: true,
    },
  ]

  const {
    handleSubmit,
    formState: { errors },
    control,
    resetField,
  } = useForm()

  async function submit(values) {
    setLoading(true)

    await onSubmit(data.collection, data.id, { ...values, status: data.status })
    setLoading(false)
    resetField()
    onClose()
  }

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      size={'lg'}
      closeOnOverlayClick={false}
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader textAlign='center'>ข้อมูลการชำระเงิน</ModalHeader>
        <ModalCloseButton />
        <ModalBody p={10}>
          <FormInputs
            errors={errors}
            control={control}
            forms={paymentStatusForm || []}
            data={data.payment}
          />
        </ModalBody>

        <ModalFooter justifyContent='center'>
          <Button mr={3} variant='outline' onClick={onClose}>
            Close
          </Button>
          <Button
            colorScheme='lime'
            onClick={handleSubmit(submit)}
            bg='brand'
            isLoading={isLoading}
          >
            Submit
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}
