import {
  Box,
  Tab,
  Tabs,
  TabList,
  TabPanels,
  TabPanel,
  Flex,
  IconButton,
} from '@chakra-ui/react'
import { BiArrowBack } from 'react-icons/bi'
import { useNavigate } from 'react-router-dom'
import BracnhInfo from './info'
import BracnhPayment from './payment'

export default function BracnhSetting() {
  const navigate = useNavigate()

  return (
    <Box p={{ base: 0, lg: 10 }}>
      <Flex justifyContent='flex-start'>
        <IconButton
          size={30}
          variant='ghost'
          color='brand'
          icon={<BiArrowBack size={30} />}
          onClick={() => navigate('/settings')}
        />
      </Flex>
      <Tabs variant='unstyled' mt={'2rem'}>
        <TabList>
          <Tab
            _focus={{ boxShadow: 'none' }}
            _selected={{ color: 'white', bg: 'brand' }}
            borderBottom='2px solid rgba(226, 232, 240, 1)'
          >
            BRANCH INFO
          </Tab>
          <Tab
            _focus={{ boxShadow: 'none' }}
            _selected={{ color: 'white', bg: 'brand' }}
            borderBottom='2px solid rgba(226, 232, 240, 1)'
          >
            PAYMENT
          </Tab>
        </TabList>
        <TabPanels>
          <TabPanel>
            <BracnhInfo />
          </TabPanel>
          <TabPanel>
            <BracnhPayment />
          </TabPanel>
        </TabPanels>
      </Tabs>
    </Box>
  )
}
