import { useState } from 'react'

import {
  Input,
  Select,
  Textarea,
  RadioGroup,
  Radio,
  Stack,
  Checkbox,
  CheckboxGroup,
  InputGroup,
  InputRightElement,
  IconButton,
} from '@chakra-ui/react'
import { BiShow, BiHide } from 'react-icons/bi'
// import DatePicker from 'react-date-picker'

import { TimePicker } from 'antd'
import moment from 'moment'

const InputComponent = ({ options, defaultValue, type, ...rest }) => {
  const [showPass, setShowPass] = useState(false)
  const handleClick = () => setShowPass(!showPass)

  switch (type) {
    case 'password':
      return (
        <InputGroup>
          <Input {...rest} type={showPass ? 'text' : 'password'} />
          <InputRightElement width='4.5rem'>
            <IconButton h='1.75rem' size='lg' onClick={handleClick}>
              {showPass ? <BiHide /> : <BiShow />}
            </IconButton>
          </InputRightElement>
        </InputGroup>
      )
    case 'text':
      return <Input {...rest} type={type} />
    case 'email':
      return <Input {...rest} type='email' />
    case 'number':
      return (
        <Input {...rest} type='number' />
        // <NumberInput {...rest}>
        //   <NumberInputField {...rest} />
        // </NumberInput>
      )
    case 'textarea':
      return <Textarea {...rest} type={type} />
    case 'date':
      return (
        // <DatePicker {...rest} locale={'th'} maxDate={new Date()} />
        <Input {...rest} type='date' />
      )
    case 'time':
      return (
        <TimePicker
          style={{ width: '100%' }}
          onChange={rest.onChange}
          // value={rest.value && moment(rest.value)}
          format={'HH:mm'}
          defaultValue={
            defaultValue !== '' && moment(rest.value || defaultValue, 'HH:mm')
          }
        />
      )
    case 'dropdown':
      return (
        <Select {...rest} placeholder={`Please Select`}>
          {options?.map((item, index) => (
            <option key={`select-${item}-${index}`} value={item}>
              {item}
            </option>
          ))}
        </Select>
      )
    case 'radio':
      return (
        <RadioGroup
          {...rest}
          placeholder={`Please Select`}
          my={2}
          colorScheme='blue'
        >
          <Stack spacing={[1, 3, 5]} direction={['column', 'column', 'row']}>
            {options?.map((item, index) => (
              <Radio key={`select-${item}-${index}`} value={item}>
                {item}
              </Radio>
            ))}
          </Stack>
        </RadioGroup>
      )
    case 'checkbox':
      return (
        <CheckboxGroup colorScheme='blue' {...rest}>
          <Stack spacing={[1, 3, 5]} direction={['column', 'column', 'row']}>
            {options?.map((item, index) => (
              <Checkbox key={`select-${item}-${index}`} value={item}>
                {item}
              </Checkbox>
            ))}
          </Stack>
        </CheckboxGroup>
      )
    default:
      return null
  }
}

export default InputComponent
