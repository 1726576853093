const infoForm1 = [
  {
    label: 'ชื่อ - นามสกุล',
    name: 'nameTH',
    type: 'text',
    placeholder: '',
    defaultValue: '',
    required: true,
  },
  {
    label: `NAME`,
    name: 'nameEN',
    type: 'text',
    placeholder: '',
    defaultValue: '',
    required: true,
  },
  {
    label: `ชื่อเล่น`,
    name: 'nickname',
    type: 'text',
    placeholder: '',
    defaultValue: '',
    required: true,
  },
  {
    label: `ว/ด/ป เกิด`,
    name: 'birthday',
    type: 'date',
    placeholder: '',
    defaultValue: '',
    required: true,
  },
]

const infoForm2 = [
  {
    label: 'สัญชาติ',
    name: 'nationality',
    type: 'text',
    placeholder: '',
    defaultValue: '',
    required: true,
  },
  {
    label: `เชื้อชาติ`,
    name: 'ethnicity',
    type: 'text',
    placeholder: '',
    defaultValue: '',
    required: true,
  },
  {
    label: `ศาสนา`,
    name: 'religion',
    type: 'text',
    placeholder: '',
    defaultValue: '',
    required: true,
  },
  {
    label: `เลขที่บัตรประชาชน`,
    name: 'idCard',
    type: 'number',
    placeholder: '',
    defaultValue: '',
    required: true,
    rules: {
      maxLength: {
        value: 13,
        message: 'เลขที่บัตรประชาชน 13 หลักเท่านั้น', // JS only: <p>error message</p> TS only support string
      },
      minLength: {
        value: 13,
        message: 'เลขที่บัตรประชาชน 13 หลักเท่านั้น', // JS only: <p>error message</p> TS only support string
      },
    },
  },
  {
    label: `จำนวนพี่น้อง`,
    name: 'sibling',
    type: 'number',
    placeholder: '',
    defaultValue: '',
    required: true,
  },
  {
    label: `เพศ`,
    name: 'gender',
    type: 'radio',
    options: ['ชาย', 'หญิง'],
    placeholder: '',
    defaultValue: '',
    required: true,
  },
  {
    label: `มือข้างถนัด`,
    name: 'dominantHand',
    type: 'checkbox',
    options: ['ขวา', 'ซ้าย'],
    placeholder: '',
    defaultValue: [],
    required: true,
  },
]

const backgroundForm = [
  {
    label: 'โรงเรียน',
    name: 'school',
    type: 'text',
    defaultValue: '',
    required: false,
  },
  {
    label: 'ของเล่นที่ชอบ/ความสนใจ',
    name: 'hobbie',
    type: 'text',
    defaultValue: '',
    required: false,
  },
  {
    label: 'ทราบข้อมูล KP จาก',
    name: 'from',
    type: 'text',
    defaultValue: '',
    required: false,
  },
]

const parent1Form = [
  {
    label: 'ชื่อผู้ปกครอง',
    name: 'parentName1',
    type: 'text',
    defaultValue: '',
    required: false,
  },
  {
    label: 'อาชีพ',
    name: 'parentJob1',
    type: 'text',
    defaultValue: '',
    required: false,
  },
  {
    label: 'เบอร์ติดต่อ',
    name: 'parentTel1',
    type: 'number',
    defaultValue: '',
    required: false,
  },
  {
    label: 'Email',
    name: 'parentEmail1',
    type: 'email',
    defaultValue: '',
    required: false,
  },
  {
    label: 'ความสัมพันธ์',
    name: 'parentRelation1',
    type: 'text',
    defaultValue: '',
    required: false,
  },
]
const parent2Form = [
  {
    label: 'ชื่อผู้ปกครอง',
    name: 'parentName2',
    type: 'text',
    defaultValue: '',
    required: false,
  },
  {
    label: 'อาชีพ',
    name: 'parentJob2',
    type: 'text',
    defaultValue: '',
    required: false,
  },
  {
    label: 'เบอร์ติดต่อ',
    name: 'parentTel2',
    type: 'number',
    defaultValue: '',
    required: false,
  },
  {
    label: 'Email',
    name: 'parentEmail2',
    type: 'email',
    defaultValue: '',
    required: false,
  },
  {
    label: 'ความสัมพันธ์',
    name: 'parentRelation2',
    type: 'text',
    defaultValue: '',
    required: false,
  },
]

const parentExtraForm = [
  {
    label: 'ชื่อ',
    name: 'parentNameExtra',
    type: 'text',
    defaultValue: '',
    required: false,
  },

  {
    label: 'เบอร์ติดต่อ',
    name: 'parentTelExtra',
    type: 'number',
    defaultValue: '',
    required: false,
  },
  {
    label: 'ความสัมพันธ์',
    name: 'parentRelationExtra',
    type: 'text',
    defaultValue: '',
    required: false,
  },
]

export {
  infoForm1,
  infoForm2,
  backgroundForm,
  parent1Form,
  parent2Form,
  parentExtraForm,
}
