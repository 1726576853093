import { Box, Flex, Avatar, HStack, Text } from '@chakra-ui/react'
import { useNavigate } from 'react-router-dom'

function kidStatus(status) {
  if (status === 'member') {
    return (
      <Box fontWeight='semibold' color='brand'>
        {status.toUpperCase()}
      </Box>
    )
  } else if (status === 'evaluation') {
    return (
      <Box fontWeight='semibold' color='#FFC211'>
        {status.toUpperCase()}
      </Box>
    )
  } else if (status === 'off') {
    return (
      <Box fontWeight='semibold' color='#767262'>
        {status.toUpperCase()}
      </Box>
    )
  }
}

export default function KidCard({ kid }) {
  const navigate = useNavigate()
  return (
    <Flex
      alignItems='center'
      justifyContent='flex-start'
      cursor='pointer'
      bg='white'
      rounded='md'
      borderWidth='1px'
      borderColor='brand'
      onClick={() => navigate(`/kids/${kid.id}`)}
    >
      <Box
        py={6}
        px={4}
        position='relative'
        display='flex'
        justifyContent='center'
        alignItems='center'
      >
        <Avatar
          size='lg'
          src={kid?.picture}
          objectFit='cover'
          alt='picture of a member'
        />
      </Box>
      <Box py='6'>
        <HStack spacing={1}>
          <Box fontWeight='semibold' as='h6' lineHeight='tight' fontSize='md'>
            OFFICE
          </Box>
          <Box fontSize='md'> : {kid?.branch}</Box>
        </HStack>
        <HStack spacing={1} fontSize='md'>
          <Box fontWeight='semibold' as='h5' lineHeight='tight'>
            KID CODE
          </Box>
          <Text>{`: ${kid?.id}`}</Text>
        </HStack>
        <Text fontSize='md'>{`${kid?.nameTH} (${kid?.nickname})`}</Text>
        {kidStatus(kid?.status || 'off')}
      </Box>
    </Flex>
  )
}
