import {
  Avatar,
  Flex,
  HStack,
  VStack,
  Text,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
} from '@chakra-ui/react'
import { signOut } from 'firebase/auth'
import { auth } from 'config/firebase'
import { useNavigate } from 'react-router-dom'

export default function UserProfile({ user }) {
  const navigate = useNavigate()

  return (
    <HStack spacing={{ base: '0', md: '6' }}>
      <Flex alignItems='center'>
        <Menu>
          <MenuButton
            py={2}
            transition='all 0.3s'
            _focus={{ boxShadow: 'none' }}
          >
            <HStack spacing='4'>
              <VStack
                display={{ base: 'none', md: 'flex' }}
                alignItems='flex-end'
                spacing='1px'
                ml='2'
              >
                <Text fontSize='lg' color='white'>
                  {user?.email}
                </Text>
                <Text fontSize='md' color='white'>
                  {user?.role}
                </Text>
              </VStack>
              <Avatar size='md' src={''} />
            </HStack>
          </MenuButton>
          <MenuList fontSize='lg' bg='white' borderColor='gray.200'>
            <MenuItem onClick={() => signOut(auth).then(navigate('/login'))}>
              Sign out
            </MenuItem>
          </MenuList>
        </Menu>
      </Flex>
    </HStack>
  )
}
