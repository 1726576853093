import { useState } from 'react'
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  useToast,
} from '@chakra-ui/react'
import { useForm } from 'react-hook-form'
import { db } from 'config/firebase'
import { doc, updateDoc, addDoc, collection } from 'firebase/firestore'

import FormInputs from 'components/DynamicForm'
import { bankForm } from '../fields'

export default function BankModal({ isOpen, onClose, data }) {
  const [isLoading, setLoading] = useState(false)
  const toast = useToast()

  const {
    handleSubmit,
    formState: { errors },
    control,
    resetField,
  } = useForm()

  function submit(values) {
    if (data?.id) {
      update(values)
    } else {
      create(values)
    }
  }
  function create(values) {
    setLoading(true)
    addDoc(collection(db, 'PaymentTypes'), {
      ...values,
      type: 'bank',
      createdAt: new Date(),
    })
      .then(() => {
        toast({
          position: 'top',
          title: 'Create Bank Payment',
          description: 'Bank Payment has been created.',
          status: 'success',
          duration: 3000,
          isClosable: true,
        })
        setLoading(false)
        resetField()
        onClose()
      })
      .catch((e) => {
        setLoading(false)
        toast({
          position: 'top',
          title: 'Create Fail.',
          description: e.message,
          status: 'error',
          duration: 3000,
          isClosable: true,
        })
      })
  }

  function update(values) {
    setLoading(true)
    updateDoc(doc(db, 'PaymentTypes', data.id), { ...values })
      .then(() => {
        toast({
          position: 'top',
          title: 'Update Bank Payment',
          description: 'Bank Payment has been updated.',
          status: 'success',
          duration: 3000,
          isClosable: true,
        })
        setLoading(false)
        resetField()
        onClose()
      })
      .catch((e) => {
        toast({
          position: 'top',
          title: 'Update Fail.',
          description: e.message,
          status: 'error',
          duration: 3000,
          isClosable: true,
        })
        setLoading(false)
      })
  }

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      size={'lg'}
      closeOnOverlayClick={false}
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader textAlign='center'>เพิ่มบัญชีธนาคาร</ModalHeader>
        <ModalCloseButton />
        <ModalBody p={10}>
          <FormInputs
            errors={errors}
            control={control}
            forms={bankForm || []}
            data={data}
          />
        </ModalBody>

        <ModalFooter justifyContent='center'>
          <Button mr={3} variant='outline' onClick={onClose}>
            Close
          </Button>
          <Button
            colorScheme='lime'
            onClick={handleSubmit(submit)}
            bg='brand'
            isLoading={isLoading}
          >
            Submit
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}
