import { useState, useEffect } from 'react'
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  useToast,
} from '@chakra-ui/react'
import { useForm } from 'react-hook-form'
import { db } from 'config/firebase'
import {
  doc,
  updateDoc,
  addDoc,
  collection,
  query,
  orderBy,
  onSnapshot,
  where,
} from 'firebase/firestore'

import FormInputs from 'components/DynamicForm'

const evaluationForm = (staff) => [
  {
    label: 'Evaluation',
    name: 'type',
    type: 'dropdown',
    options: ['ประเมินแรกเข้า OT', 'ประเมินแรกเข้า SE', 'ประเมินแรกเข้า SP'],
    placeholder: '',
    defaultValue: '',
    required: true,
  },
  {
    label: `Evaluation Date`,
    name: 'date',
    type: 'date',
    placeholder: '',
    defaultValue: '',
    required: true,
  },
  {
    label: `Evaluation Fee`,
    name: 'fee',
    type: 'number',
    placeholder: '',
    defaultValue: '',
    required: true,
  },
  {
    label: `ผู้ประเมิน`,
    name: 'staff',
    type: 'dropdown',
    options: staff,
    placeholder: '',
    defaultValue: '',
    required: true,
  },
]
export default function EvaluationModal({ isOpen, onClose, data, kidId }) {
  const [isLoading, setLoading] = useState(false)
  const toast = useToast()
  const [staff, setStaff] = useState([])

  const {
    handleSubmit,
    formState: { errors },
    control,
    resetField,
  } = useForm()

  useEffect(() => {
    const queryBranches = query(
      collection(db, 'Profiles'),
      where('role', '==', 'Staff'),
      orderBy('createdAt', 'desc')
    )
    const unsubscribe = onSnapshot(queryBranches, (snapShot) => {
      let data = []
      snapShot.forEach((doc) => {
        data.push(doc.data().fullname)
      })
      setStaff(data)
    })
    return () => {
      unsubscribe()
    }
  }, [])

  function submit(values) {
    if (data?.id) {
      update(values)
    } else {
      create(values)
    }
  }
  function create(values) {
    setLoading(true)
    addDoc(collection(db, `Kids/${kidId}/Evaluation`), {
      ...values,
      createdAt: new Date(),
    })
      .then(() => updateDoc(doc(db, `Kids`, kidId), { status: 'evaluation' }))
      .then(() => {
        toast({
          position: 'top',
          title: 'Add Kid Evaluation',
          description: 'Kid Evaluation has been created.',
          status: 'success',
          duration: 3000,
          isClosable: true,
        })
        setLoading(false)
        resetField()
        onClose()
      })
      .catch((e) => {
        setLoading(false)
        toast({
          position: 'top',
          title: 'Add Fail.',
          description: e.message,
          status: 'error',
          duration: 3000,
          isClosable: true,
        })
      })
  }

  function update(values) {
    setLoading(true)
    updateDoc(doc(db, `Kids/${kidId}/Evaluation`, data.id), { ...values })
      .then(() => updateDoc(doc(db, `Kids`, kidId), { status: 'evaluation' }))
      .then(() => {
        toast({
          position: 'top',
          title: 'Update Kid Evaluation',
          description: 'Kid Evaluation has been updated.',
          status: 'success',
          duration: 3000,
          isClosable: true,
        })
        setLoading(false)
        resetField()
        onClose()
      })
      .catch((e) => {
        toast({
          position: 'top',
          title: 'Update Fail.',
          description: e.message,
          status: 'error',
          duration: 3000,
          isClosable: true,
        })
        setLoading(false)
      })
  }

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      size={'lg'}
      closeOnOverlayClick={false}
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader textAlign='center'>Add Evaluation</ModalHeader>
        <ModalCloseButton />
        <ModalBody p={10}>
          <FormInputs
            errors={errors}
            control={control}
            forms={evaluationForm(staff) || []}
            data={data}
          />
        </ModalBody>

        <ModalFooter justifyContent='center'>
          <Button mr={3} variant='outline' onClick={onClose}>
            Close
          </Button>
          <Button
            colorScheme='lime'
            onClick={handleSubmit(submit)}
            bg='brand'
            isLoading={isLoading}
          >
            Submit
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}
