import { Fragment, useMemo } from 'react'
import { useNavigate, useLocation } from 'react-router-dom'
import { Flex, Icon, Text } from '@chakra-ui/react'

export default function NavLink({ onClose, link, ...rest }) {
  const { label, icon, href } = link
  const location = useLocation()
  const navigate = useNavigate()

  const activeLink = useMemo(
    () => location?.pathname.includes(href),
    [location?.pathname, href]
  )

  return (
    <Fragment>
      <Flex
        onClick={() => {
          if (link.href !== '/#') {
            onClose()
            navigate(link.href)
          }
        }}
        flexDirection='column'
        align='center'
        justifyContent='center'
        p='2'
        m='1'
        borderRadius='lg'
        role='group'
        cursor='pointer'
        color='white'
        _hover={{
          bg: link.href !== '/#' && 'rgba(255, 255, 255, 0.5)',
        }}
        _active={{
          bg: 'rgba(255, 255, 255, 0.5)',
        }}
        bg={link.href !== '/#' && activeLink && 'rgba(255, 255, 255, 0.5)'}
        {...rest}
      >
        {icon && (
          <Icon
            fontSize={{ base: '35', lg: '40' }}
            _groupHover={{
              color: 'white',
            }}
            as={icon}
          />
        )}
        <Text fontSize='16px'>{label}</Text>
      </Flex>
      <hr />
    </Fragment>
  )
}
