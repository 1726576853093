/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect, useMemo } from 'react'
import {
  Box,
  SimpleGrid,
  Flex,
  Button,
  Text,
  FormControl,
  FormLabel,
  Input,
  Select,
  HStack,
  Heading,
  Center,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  IconButton,
  useToast,
} from '@chakra-ui/react'
import {
  onSnapshot,
  query,
  collection,
  orderBy,
  doc,
  writeBatch,
} from 'firebase/firestore'
import { search } from 'ss-search'
import { db } from 'config/firebase'
import { AiFillCaretDown, AiOutlinePrinter } from 'react-icons/ai'
import {
  MdOutlineHighlightOff,
  MdOutlineDownloadForOffline,
} from 'react-icons/md'

import { Table, Popconfirm } from 'antd'
import { Link } from 'react-router-dom'

export default function Payments() {
  const toast = useToast()
  const [paymentQuery, setPaymentQuery] = useState([])
  const [branches, setBranches] = useState([])
  const [allPayment, setAllPayment] = useState([])
  const [loadAllData, setLoadAllData] = useState(true)
  const [searchQuery, setSearch] = useState({
    text: '',
    branch: '',
    status: '',
  })

  useEffect(() => {
    const queryPayment = query(
      collection(db, 'Payments'),
      orderBy('createdAt', 'desc')
    )
    const unsubscribe = onSnapshot(queryPayment, (snapShot) => {
      let data = []
      snapShot.forEach((doc) => {
        data.push({ ...doc.data(), id: doc.id })
      })
      setAllPayment(data)
      setPaymentQuery(data)
      setLoadAllData(false)
    })
    return () => {
      unsubscribe()
    }
  }, [])

  useEffect(() => {
    const queryBranches = query(
      collection(db, 'Branches'),
      orderBy('createdAt', 'desc')
    )
    const unsubscribe = onSnapshot(queryBranches, (snapShot) => {
      let data = []
      snapShot.forEach((doc) => {
        data.push({ ...doc.data(), id: doc.id })
      })
      setBranches(data)
    })
    return () => {
      unsubscribe()
    }
  }, [])

  function handleSearch(e) {
    e.preventDefault()
    let textSearch = searchQuery.text
    const searchQueryArr = allPayment
      .filter((payment) =>
        searchQuery.branch !== ''
          ? payment.branch === searchQuery.branch
          : payment
      )
      .filter((payment) =>
        searchQuery.status !== ''
          ? payment.status === searchQuery.status
          : payment
      )
    const searchKeys = ['nameTH', 'nickname']
    const results = search(searchQueryArr, searchKeys, textSearch)
    const searchResults = textSearch === '' ? searchQueryArr : results
    setPaymentQuery(searchResults)
  }

  const columns = useMemo(() => {
    return [
      {
        title: 'เลขที่เอกสาร',
        dataIndex: 'id',
        key: 'id',
        width: '10%',
      },
      {
        title: 'ชื่อลูกค้า',
        dataIndex: 'name',
        key: 'name',
        width: '10%',
      },
      {
        title: 'สาขา',
        dataIndex: 'branch',
        key: 'branch',
        width: '10%',
      },
      {
        title: 'วันที่',
        dataIndex: 'createdAt',
        key: 'createdAt',
        width: '10%',
        render: (date) => {
          return date.toDate().toLocaleString()
        },
      },
      {
        title: 'การชำระเงิน',
        dataIndex: 'payment',
        key: 'payment',
        width: '10%',
      },
      {
        title: 'Status',
        dataIndex: 'status',
        key: 'status',
        width: '10%',
        align: 'center',
        render: (status, row) => {
          return (
            <Heading
              fontSize='md'
              color={status === 'paid' ? 'brand' : 'red'}
              textTransform={'uppercase'}
            >
              {status}
            </Heading>
          )
        },
      },
      {
        title: 'ยอดรวมสุทธิ',
        dataIndex: 'totalPrice',
        key: 'totalPrice',
        align: 'right',
        width: '10%',
        render: (data, row) => `${Number(data).toLocaleString('th-TH')} บาท`,
      },
      {
        title: '',
        dataIndex: 'Action',
        key: 'Action',
        width: '5%',
        align: 'center',
        render: (data, row) => {
          return (
            row?.status === 'paid' && (
              <HStack spacing={0} m={0} p={0}>
                <IconButton
                  variant='ghost'
                  aria-label='print'
                  icon={<AiOutlinePrinter color='teal' fontSize='25px' />}
                />
                <IconButton
                  variant='ghost'
                  aria-label='download'
                  icon={<MdOutlineDownloadForOffline fontSize='25px' />}
                />

                <Popconfirm
                  title='Sure to cancel this Payment'
                  onConfirm={() => {
                    handleCancel(row)
                  }}
                >
                  <IconButton
                    variant='ghost'
                    aria-label='cancel'
                    icon={<MdOutlineHighlightOff color='red' fontSize='25px' />}
                  />
                </Popconfirm>
              </HStack>
            )
          )
        },
      },
    ]
  }, [])

  async function handleCancel(row) {
    const { id, kidId } = row
    try {
      const batch = writeBatch(db)

      batch.set(
        doc(db, 'Payments', id),
        {
          status: 'cancel',
        },
        { merge: true }
      )

      row?.programs.forEach((program) => {
        if (program?.id) {
          batch.set(
            doc(db, `Kids/${kidId}/${program?.collection}`, program?.id),
            {
              payment: {
                createdBy: '',
                createdAt: '',
                paymentNo: '',
                status: 'waiting',
              },
            },
            { merge: true }
          )
        }
      })

      await batch.commit()

      toast({
        position: 'top',
        title: 'Delete Payment',
        description: 'Payment has been Deleted.',
        status: 'success',
        duration: 3000,
        isClosable: true,
      })
    } catch (error) {
      toast({
        position: 'top',
        title: 'Delete Fail.',
        description: error.message,
        status: 'error',
        duration: 3000,
        isClosable: true,
      })
    }
  }

  return (
    <Box p={{ base: 10, lg: 16 }}>
      <Center pb={4}>
        <Heading>PAYMENTS</Heading>
      </Center>

      <Text color='brand'>Search</Text>
      <form onSubmit={handleSearch}>
        <Box
          borderWidth='1px'
          borderColor='brand'
          borderRadius='10px'
          mt={1}
          mb={10}
          p={5}
        >
          <SimpleGrid
            columns={{ base: 1, md: 3 }}
            spacing={{ base: 0, md: 10 }}
          >
            <FormControl>
              <FormLabel>Search</FormLabel>
              <Input
                id='search'
                type='search'
                placeholder='Search by Name'
                onChange={(e) =>
                  setSearch({ ...searchQuery, text: e.target.value })
                }
                value={searchQuery.text}
              />
            </FormControl>
            <FormControl>
              <FormLabel>Branch</FormLabel>
              <Select
                id='branch'
                placeholder='All branches'
                onChange={(e) =>
                  setSearch({ ...searchQuery, branch: e.target.value })
                }
                value={searchQuery.branch}
              >
                {branches.map((branch) => (
                  <option key={branch.id} value={branch.branchName}>
                    {branch.branchName}
                  </option>
                ))}
              </Select>
            </FormControl>
          </SimpleGrid>
          <Flex
            justifyContent={{ base: 'center', lg: 'flex-end' }}
            w='full'
            mt={4}
          >
            <HStack mt={3}>
              <Button
                variant='outline'
                onClick={() => {
                  setSearch({ text: '', branch: '', status: '' })
                  setPaymentQuery(allPayment)
                }}
              >
                Clear
              </Button>
              <Button variant='solid' colorScheme='twitter' type='submit'>
                Search
              </Button>
            </HStack>
          </Flex>
        </Box>
      </form>

      <Flex justifyContent='flex-end' w='full' mb='10'>
        <Menu>
          <MenuButton
            as={Button}
            rightIcon={<AiFillCaretDown />}
            variant='solid'
            colorScheme='orange'
            bg='#F6872B'
          >
            Create Payment
          </MenuButton>
          <MenuList justifyContent='center' alignItems='center'>
            <Link to={'/payments/bill/create'}>
              <MenuItem textAlign='center'>บิลเงินสด</MenuItem>
            </Link>
            <Link to={'/payments/receipt/create'}>
              <MenuItem textAlign='center'>ใบเสร็จรับเงิน</MenuItem>
            </Link>
          </MenuList>
        </Menu>
      </Flex>
      <Table
        bordered
        rowClassName={() => 'rowClassName1'}
        size='small'
        dataSource={paymentQuery}
        columns={columns}
        loading={loadAllData}
        rowKey='id'
        scroll={{ x: 'max-content' }}
      />
    </Box>
  )
}
