/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect } from 'react'
import KidCard from 'components/KidCard'
import {
  Box,
  SimpleGrid,
  Flex,
  Button,
  Text,
  FormControl,
  FormLabel,
  Input,
  Select,
  HStack,
  Heading,
  Center,
  ScaleFade,
} from '@chakra-ui/react'
import { Link } from 'react-router-dom'
import { onSnapshot, query, collection, orderBy } from 'firebase/firestore'
import { search } from 'ss-search'
import { db } from 'config/firebase'
import SkeletoCard from 'components/SkeletonCard'

export default function Kids() {
  const [kidQuery, setKidQuery] = useState([])
  const [branches, setBranches] = useState([])
  const [allKid, setAllKid] = useState([])
  const [loadAllData, setLoadAllData] = useState(true)
  const [searchQuery, setSearch] = useState({
    text: '',
    branch: '',
    status: '',
  })

  useEffect(() => {
    const queryKid = query(collection(db, 'Kids'), orderBy('createdAt', 'desc'))
    const unsubscribe = onSnapshot(queryKid, (snapShot) => {
      let data = []
      snapShot.forEach((doc) => {
        data.push({ ...doc.data(), id: doc.id })
      })
      setAllKid(data)
      setKidQuery(data)
      setLoadAllData(false)
    })
    return () => {
      unsubscribe()
    }
  }, [])

  useEffect(() => {
    const queryBranches = query(
      collection(db, 'Branches'),
      orderBy('createdAt', 'desc')
    )
    const unsubscribe = onSnapshot(queryBranches, (snapShot) => {
      let data = []
      snapShot.forEach((doc) => {
        data.push({ ...doc.data(), id: doc.id })
      })
      setBranches(data)
    })
    return () => {
      unsubscribe()
    }
  }, [])

  function handleSearch(e) {
    e.preventDefault()
    let textSearch = searchQuery.text
    const searchQueryArr = allKid
      .filter((kid) =>
        searchQuery.branch !== '' ? kid.branch === searchQuery.branch : kid
      )
      .filter((kid) =>
        searchQuery.status !== '' ? kid.status === searchQuery.status : kid
      )
    const searchKeys = ['nameTH', 'nickname']
    const results = search(searchQueryArr, searchKeys, textSearch)
    const searchResults = textSearch === '' ? searchQueryArr : results
    setKidQuery(searchResults)
  }

  return (
    <Box p={{ base: 10, lg: 16 }}>
      <Text color='brand'>Search</Text>
      <form onSubmit={handleSearch}>
        <Box
          borderWidth='1px'
          borderColor='brand'
          borderRadius='10px'
          mt={1}
          mb={10}
          p={5}
        >
          <SimpleGrid
            columns={{ base: 1, md: 3 }}
            spacing={{ base: 0, md: 10 }}
          >
            <FormControl>
              <FormLabel>Search</FormLabel>
              <Input
                id='search'
                type='search'
                onChange={(e) =>
                  setSearch({ ...searchQuery, text: e.target.value })
                }
                value={searchQuery.text}
              />
            </FormControl>
            <FormControl>
              <FormLabel>Status</FormLabel>
              <Select
                id='status'
                placeholder='Status'
                onChange={(e) =>
                  setSearch({ ...searchQuery, status: e.target.value })
                }
                value={searchQuery.status}
              >
                <option value='member'>MEMBER</option>
                <option value='evaluation'>EVALUATION</option>
                <option value='off'>OFF</option>
              </Select>
            </FormControl>
            <FormControl>
              <FormLabel>Branch</FormLabel>
              <Select
                id='branch'
                placeholder='All branches'
                onChange={(e) =>
                  setSearch({ ...searchQuery, branch: e.target.value })
                }
                value={searchQuery.branch}
              >
                {branches.map((branch) => (
                  <option key={branch.id} value={branch.branchName}>
                    {branch.branchName}
                  </option>
                ))}
              </Select>
            </FormControl>
          </SimpleGrid>
          <Flex
            justifyContent={{ base: 'center', lg: 'flex-end' }}
            w='full'
            mt={4}
          >
            <HStack mt={3}>
              <Button
                variant='outline'
                onClick={() => {
                  setSearch({ text: '', branch: '', status: '' })
                  setKidQuery(allKid)
                }}
              >
                Clear
              </Button>
              <Button variant='solid' colorScheme='twitter' type='submit'>
                Search
              </Button>
            </HStack>
          </Flex>
        </Box>
      </form>

      <Flex justifyContent='flex-end' w='full'>
        <Link to='/kids/create'>
          <Button variant='solid' colorScheme='lime' bg='brand'>
            Create Kid Info
          </Button>
        </Link>
      </Flex>
      {loadAllData ? (
        <SimpleGrid columns={[1, 2, 2, 2, 3, 4]} mt='4' spacing='1em'>
          {new Array(10).fill(null).map((d, i) => (
            <ScaleFade in={i + 1} initialScale={0.5} key={i}>
              <SkeletoCard />
            </ScaleFade>
          ))}
        </SimpleGrid>
      ) : kidQuery.length === 0 ? (
        <Center
          borderWidth='1px'
          borderColor='brand'
          borderRadius='10px'
          h='200px'
          my={10}
          p={5}
        >
          <Heading color={'brand'}>No data available</Heading>
        </Center>
      ) : (
        <SimpleGrid columns={[1, 2, 2, 2, 3, 4]} mt='4' spacing='1em'>
          {kidQuery.map((kid, i) => (
            <ScaleFade in={i + 1} initialScale={0.5} key={i}>
              <KidCard kid={kid} />
            </ScaleFade>
          ))}
        </SimpleGrid>
      )}
    </Box>
  )
}
