/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect, useMemo } from 'react'

import {
  Box,
  Flex,
  Heading,
  VStack,
  HStack,
  Button,
  IconButton,
  useDisclosure,
  Input,
  InputGroup,
  useToast,
} from '@chakra-ui/react'
import {
  onSnapshot,
  query,
  collection,
  where,
  orderBy,
} from 'firebase/firestore'
import { Table, Popconfirm } from 'antd'
import { search } from 'ss-search'
import { db, functions } from 'config/firebase'
import { AiFillEdit, AiFillDelete } from 'react-icons/ai'
import { httpsCallable } from 'firebase/functions'
import { BiArrowBack } from 'react-icons/bi'
import { useNavigate } from 'react-router-dom'

import Modal from './Modal/admin'

const deleteAccount = httpsCallable(functions, 'deleteAccount')

export default function AdminSetting() {
  const { isOpen, onOpen, onClose } = useDisclosure()
  const [allStaff, setAllStaff] = useState([])
  const [editData, setEdit] = useState(null)
  const [staffQuery, setStaffQuery] = useState([])
  const [loadAllStaff, setLoadAllStaff] = useState(true)
  const [branches, setBranches] = useState()
  const navigate = useNavigate()
  const toast = useToast()

  useEffect(() => {
    const queryStaff = query(
      collection(db, 'Profiles'),
      where('role', '==', 'Admin'),
      orderBy('createdAt', 'desc')
    )
    const unsubscribe = onSnapshot(queryStaff, (snapShot) => {
      let data = []
      snapShot.forEach((doc) => {
        data.push({ ...doc.data(), id: doc.id })
      })
      setAllStaff(data)
      setStaffQuery(data)
      setLoadAllStaff(false)
    })
    return () => {
      console.log('unmount')
      unsubscribe()
    }
  }, [])

  useEffect(() => {
    const queryBranches = query(
      collection(db, 'Branches'),
      orderBy('createdAt', 'desc')
    )
    const unsubscribe = onSnapshot(queryBranches, (snapShot) => {
      let data = [
        {
          label: 'Branch',
          name: 'branch',
          type: 'dropdown',
          options: [],
          placeholder: '',
          defaultValue: '',
          required: true,
        },
      ]
      snapShot.forEach((doc) => {
        data[0].options.push(doc.data().branchName)
      })
      setBranches(data)
    })
    return () => {
      console.log('unmount')
      unsubscribe()
    }
  }, [])

  function handleDelete(id) {
    deleteAccount({ uid: id })
      .then(() => {
        toast({
          position: 'top',
          title: 'Delete Admin',
          description: 'Admin has been Deleted.',
          status: 'success',
          duration: 3000,
          isClosable: true,
        })
      })
      .catch((e) => {
        toast({
          position: 'top',
          title: 'Delete Fail.',
          description: e.message,
          status: 'error',
          duration: 3000,
          isClosable: true,
        })
      })
  }

  const columns = useMemo(() => {
    return [
      {
        title: 'Email',
        dataIndex: 'email',
        key: 'email',
        width: '20%',
      },
      {
        title: 'Full Name',
        dataIndex: 'fullname',
        key: 'fullname',
        width: '20%',
      },
      {
        title: 'Nick Name',
        dataIndex: 'nickname',
        key: 'nickname',
        width: '20%',
      },
      {
        title: 'Office Branch',
        dataIndex: 'branch',
        key: 'branch',
        width: '20%',
      },
      {
        title: 'Action',
        dataIndex: 'Action',
        key: 'Action',
        width: '10%',
        render: (data, row) => {
          return (
            <HStack justifyContent='space-between'>
              <HStack spacing={2}>
                <IconButton
                  variant='ghost'
                  aria-label='edit'
                  icon={<AiFillEdit color='brand' fontSize='25px' />}
                  onClick={() => {
                    setEdit(row)
                    onOpen()
                  }}
                />
                <Popconfirm
                  title='Sure to delete this Admin'
                  onConfirm={() => {
                    handleDelete(row.id)
                  }}
                >
                  <IconButton
                    variant='ghost'
                    aria-label='delete'
                    icon={<AiFillDelete color='red' fontSize='25px' />}
                  />
                </Popconfirm>
              </HStack>
            </HStack>
          )
        },
      },
    ]
  }, [onOpen])

  return (
    <Box p={{ base: 2, lg: 10 }}>
      <Flex justifyContent='flex-start'>
        <IconButton
          size={30}
          variant='ghost'
          color='brand'
          icon={<BiArrowBack size={30} />}
          onClick={() => navigate('/settings')}
        />
      </Flex>
      <VStack spacing={10} mb={10}>
        {isOpen && (
          <Modal
            isOpen={isOpen}
            onClose={() => {
              setEdit(null)
              onClose()
            }}
            data={editData}
            branches={branches}
          />
        )}
        <Heading>ADMIN</Heading>
        <Flex justifyContent='flex-end' w={'100%'}>
          <Button
            variant='solid'
            colorScheme='orange'
            bg='#F6872B'
            onClick={onOpen}
          >
            Create Admin
          </Button>
        </Flex>
        <Flex justifyContent='flex-start' w={'100%'}>
          <InputGroup size='lg' maxW={350}>
            <Input
              pr='4.5rem'
              onChange={(e) => {
                let textSearch = e.currentTarget.value
                const searchKeys = ['nickname', 'fullname', 'email']
                const results = search(allStaff, searchKeys, textSearch)
                const searchResults = textSearch === '' ? allStaff : results
                setStaffQuery(searchResults)
              }}
              placeholder='Search by Name, Email'
            />
          </InputGroup>
        </Flex>
      </VStack>
      <Table
        bordered
        size='small'
        dataSource={staffQuery}
        columns={columns}
        loading={loadAllStaff}
        rowKey='id'
        scroll={{ x: 'max-content' }}
      />
    </Box>
  )
}
