/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect, Fragment, useRef, useMemo } from 'react'
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  Button,
  useToast,
  FormControl,
  FormLabel,
  FormErrorMessage,
  SimpleGrid,
  Input,
  Checkbox,
  Select,
} from '@chakra-ui/react'
import { useForm, useFieldArray } from 'react-hook-form'
import { db } from 'config/firebase'
import { doc, updateDoc } from 'firebase/firestore'

export default function UpdateCourseModal({
  isOpen,
  onClose,
  data,
  kidId,
  staffs,
}) {
  const [isLoading, setLoading] = useState(false)
  const toast = useToast()
  const initialRef = useRef(null)

  const {
    handleSubmit,
    formState: { errors },
    control,
    register,
    watch,
  } = useForm()

  const { fields, append } = useFieldArray({
    control,
    name: 'takedCourse',
  })

  const corseCheckedForm = [
    {
      label: `วันที่`,
      name: 'date',
      type: 'date',
      placeholder: '',
      defaultValue: '',
      required: true,
    },
    {
      label: `เวลา`,
      name: 'time',
      type: 'time',
      placeholder: '',
      defaultValue: '',
      required: true,
    },
    {
      label: `ครูผู้สอน`,
      name: 'staff',
      type: 'dropdown',
      options: staffs,
      placeholder: '',
      defaultValue: '',
      required: true,
    },
  ]

  const takedCourse = watch(`takedCourse`)

  useEffect(() => {
    if (data?.takedCourse) {
      fields.length === 0 &&
        data?.takedCourse.map((program) =>
          append({
            date: program.date,
            isTaked: program.isTaked,
            staff: program.staff,
            time: program.time,
          })
        )
    }
  }, [data])

  function updateTakedCouse(values) {
    setLoading(true)
    updateDoc(
      doc(db, `Kids/${kidId}/Programs`, data.id),
      {
        ...values,
      },
      { merge: true }
    )
      .then(() => {
        toast({
          position: 'top',
          title: 'Update Kid Program',
          description: 'Kid Program has been updated.',
          status: 'success',
          duration: 3000,
          isClosable: true,
        })
        setLoading(false)
      })
      .catch((e) => {
        setLoading(false)
        toast({
          position: 'top',
          title: 'Update Fail.',
          description: e.message,
          status: 'error',
          duration: 3000,
          isClosable: true,
        })
      })
  }
  const getTakedCouse = useMemo(
    () =>
      data.takedCourse.reduce(
        (total, course) => total + (course.isTaked ? 1 : 0),
        0
      ),
    [data.takedCourse]
  )

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      size={'full'}
      closeOnOverlayClick={false}
      scrollBehavior={'inside'}
      initialFocusRef={initialRef}
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          {`${data.name} / ${data.choice} `}
          <span
            style={{ color: '#01AEF0' }}
          >{` (${getTakedCouse} /${data.amount})`}</span>
        </ModalHeader>
        <hr />
        <ModalBody px={10} ref={initialRef}>
          <SimpleGrid
            columns={[1, 4]}
            spacingX={[1, 1, 1, 5]}
            mx={{ base: 0, md: 2, lg: 10, xl: 20 }}
            justifyContent='center'
            alignItems='center'
          >
            {fields.map((item, i) => {
              return (
                <Fragment key={i}>
                  <FormControl
                    key={`takedCourse-${i}`}
                    mb='3'
                    isInvalid={errors.takedCourse?.[i]?.[data.name]}
                    id={`takedCourse[${i}]name`}
                    display='grid'
                    justifyContent='center'
                    alignItems='center'
                    h='-webkit-fill-available'
                  >
                    <FormLabel mr={3}>{`ครั้งที่ ${i + 1}`}</FormLabel>

                    <Checkbox
                      {...register(`takedCourse.${i}.isTaked`, {
                        required: data.required,
                      })}
                      name={`takedCourse.${i}.isTaked`}
                      defaultChecked={item.isTaked}
                      size='lg'
                      colorScheme='green'
                    />
                    <FormErrorMessage>
                      {errors.takedCourse?.[i]?.[data.name] &&
                        `${data?.label} is Require Field `}
                    </FormErrorMessage>
                  </FormControl>
                  {corseCheckedForm.map((data) => (
                    <FormControl
                      key={`takedCourse.${i}.${data.name}`}
                      mb='3'
                      isInvalid={
                        takedCourse[i].isTaked &&
                        errors.takedCourse?.[i]?.[data.name]
                      }
                      id={`takedCourse[${i}]name`}
                    >
                      <FormLabel>{`${data.label}`}</FormLabel>
                      {data.type !== 'dropdown' ? (
                        <Input
                          {...register(`takedCourse.${i}.${data.name}`, {
                            required: takedCourse[i].isTaked && data.required,
                          })}
                          name={`takedCourse.${i}.${data.name}`}
                          type={data.type}
                          placeholder={data.placeholder}
                          defaultValue={item[data.name]}
                          disabled={!takedCourse[i].isTaked}
                        />
                      ) : (
                        <Select
                          placeholder={`Please Select`}
                          {...register(`takedCourse.${i}.${data.name}`, {
                            required: takedCourse[i].isTaked && data.required,
                          })}
                          defaultValue={item[data.name]}
                        >
                          {data.options?.map((item, index) => (
                            <option
                              key={`select-${item}-${index}`}
                              value={item}
                            >
                              {item}
                            </option>
                          ))}
                        </Select>
                      )}
                      <FormErrorMessage>
                        {takedCourse[i].isTaked &&
                          errors.takedCourse?.[i]?.[data.name] &&
                          `${data?.label} is Require Field `}
                      </FormErrorMessage>
                    </FormControl>
                  ))}
                </Fragment>
              )
            })}
          </SimpleGrid>
        </ModalBody>

        <ModalFooter justifyContent='center'>
          <Button mr={3} variant='outline' onClick={onClose}>
            Close
          </Button>
          <Button
            colorScheme='lime'
            onClick={handleSubmit(updateTakedCouse)}
            bg='brand'
            isLoading={isLoading}
          >
            Save
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}
