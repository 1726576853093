import { Box, Container, Stack, Text, Image, Flex } from '@chakra-ui/react'

const Header = ({ bg }) => {
  return (
    <Flex
      height={{ base: 150, md: 200 }}
      bg={bg}
      justifyContent='center'
      alignItems='center'
    >
      <Image
        ml={{ base: 0, md: 2 }}
        height={{ base: 100, md: 150 }}
        src='/Logo.svg'
      />
    </Flex>
  )
}

const Footer = () => {
  return (
    <Container as='footer' role='contentinfo' py={{ base: '12', md: '16' }}>
      <Stack spacing={{ base: '4', md: '5' }}>
        <Text fontSize='sm' color='subtle' textAlign='center'>
          © 2022 KIDS PLUS. All Rights Reserved
        </Text>
      </Stack>
    </Container>
  )
}

export default function AuthLayout({ children, bg }) {
  return (
    <Box minH='100vh' bg='white'>
      <Header bg={bg} />
      <Box p={2}>{children}</Box>
      <Footer />
    </Box>
  )
}
