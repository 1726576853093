import React from 'react'
import { Controller } from 'react-hook-form'
import {
  FormControl,
  FormLabel,
  FormErrorMessage,
  Text,
  HStack,
} from '@chakra-ui/react'
import InputComponent from './input'

const FormInputs = ({ errors, control, forms, data, style }) =>
  forms?.map((form) => {
    const {
      name,
      label,
      type,
      required,
      options,
      defaultValue,
      placeholder,
      hideOnEdit,
      rules,
    } = form

    if (data && hideOnEdit) {
      return null
    }
    return (
      <FormControl
        my={3}
        id={name}
        isInvalid={errors[name]}
        key={name}
        w={'100%'}
      >
        <FormLabel>
          <HStack spacing={0}>
            <Text>{label}</Text>
            {required && <Text color='red'>*</Text>}{' '}
          </HStack>
        </FormLabel>
        <Controller
          name={name}
          control={control}
          rules={{
            ...rules,
            required: required && `${label} is Require Field`,
          }}
          defaultValue={(data && data[name]) || defaultValue}
          render={({ field: { onChange, value } }) => (
            <InputComponent
              onChange={onChange}
              value={value}
              placeholder={placeholder}
              type={type}
              options={options}
              defaultValue={(data && data[name]) || defaultValue}
            />
          )}
        />
        <FormErrorMessage>
          {errors[name] && errors[name].message}
        </FormErrorMessage>
      </FormControl>
    )
  })

export default FormInputs
