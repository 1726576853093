/* eslint-disable react-hooks/exhaustive-deps */
import { Fragment, useMemo, useState, useEffect, useRef } from 'react'
import {
  Center,
  Button,
  Box,
  HStack,
  Heading,
  SimpleGrid,
  FormControl,
  Input,
  FormLabel,
  FormErrorMessage,
  IconButton,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  TableContainer,
  useToast,
  Spinner,
  Flex,
  NumberInput,
  NumberInputField,
  useDisclosure,
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
  AlertDialogCloseButton,
} from '@chakra-ui/react'
import { useForm, useFieldArray, Controller } from 'react-hook-form'
import { Link, useNavigate, useParams } from 'react-router-dom'
import { AiFillDelete } from 'react-icons/ai'
import { db } from 'config/firebase'
import {
  onSnapshot,
  query,
  doc,
  updateDoc,
  addDoc,
  collection,
} from 'firebase/firestore'
import { BiArrowBack } from 'react-icons/bi'

import FormInputs from 'components/DynamicForm'
import ColorPickerInput from 'components/ColorPickerInput'

import { courseForm, programForm } from './fields'

export default function CourseForm() {
  const navigate = useNavigate()
  const { id } = useParams()
  const toast = useToast()
  const [isLoading, setLoading] = useState(false)
  const [isLoadingData, setLoadingData] = useState(false)
  const [data, setdata] = useState()
  const { isOpen, onOpen, onClose } = useDisclosure()
  const cancelRef = useRef()

  const {
    handleSubmit,
    formState: { errors },
    control,
    resetField,
    register,
    getValues,
    watch,
    reset,
  } = useForm()

  const watchPrograms = watch(`programs`)

  const programs = useMemo(() => getValues('programs'), [watchPrograms])

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'programs',
  })

  useEffect(() => {
    if (id) {
      const queryCourse = query(doc(db, 'Courses', id))
      const unsubscribe = onSnapshot(queryCourse, (snapShot) => {
        setdata({
          ...snapShot.data(),
          id: snapShot.id,
        })
        if (snapShot.data()?.programs.length > 0) {
          reset()
          snapShot.data().programs.map((program) =>
            append({
              choice: program.choice,
              amount: program.amount,
              unit: program.unit,
              color: program.color,
              price: program.price,
            })
          )
        }
        setLoadingData(false)
      })
      return () => {
        unsubscribe()
      }
    }
  }, [id])

  function addInput() {
    append({
      unit: 'ครั้ง',
      color: '#FFF',
    })
  }

  function submit(values) {
    setLoading(true)
    if (data?.id) {
      updateCourse(values)
    } else {
      createCourse(values)
    }
  }

  function createCourse(values) {
    addDoc(collection(db, 'Courses'), {
      ...values,
      createdAt: new Date(),
    })
      .then(() => {
        toast({
          position: 'top',
          title: 'Create Course',
          description: 'Course has been created.',
          status: 'success',
          duration: 3000,
          isClosable: true,
        })
        setLoading(false)
        resetField()
        navigate('/settings/course')
      })
      .catch((e) => {
        setLoading(false)
        toast({
          position: 'top',
          title: 'Create Fail.',
          description: e.message,
          status: 'error',
          duration: 3000,
          isClosable: true,
        })
      })
  }

  function updateCourse(values) {
    updateDoc(doc(db, 'Courses', data.id), {
      ...values,
    })
      .then(() => {
        toast({
          position: 'top',
          title: 'Update Course',
          description: 'Course has been updated.',
          status: 'success',
          duration: 3000,
          isClosable: true,
        })
        setLoading(false)
        resetField()
        navigate('/settings/course')
      })
      .catch((e) => {
        toast({
          position: 'top',
          title: 'Update Fail.',
          description: e.message,
          status: 'error',
          duration: 3000,
          isClosable: true,
        })
        setLoading(false)
      })
  }
  if (isLoadingData || (id && !data)) {
    return (
      <Center>
        <Spinner />
      </Center>
    )
  }

  const header = ['Program', 'Amount', 'Unit', 'Price', 'Color']

  return (
    <Box p={10}>
      <AlertDialog
        motionPreset='slideInBottom'
        leastDestructiveRef={cancelRef}
        onClose={onClose}
        isOpen={isOpen}
        isCentered
      >
        <AlertDialogOverlay />

        <AlertDialogContent>
          <AlertDialogHeader>Discard Changes?</AlertDialogHeader>
          <AlertDialogCloseButton />
          <AlertDialogBody>
            Are you sure you want to discard all of your input
          </AlertDialogBody>
          <AlertDialogFooter>
            <Button ref={cancelRef} onClick={onClose}>
              No
            </Button>
            <Link to={'/settings/course'}>
              <Button colorScheme='red' ml={3}>
                Yes
              </Button>
            </Link>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>
      <Flex justifyContent='flex-start'>
        <IconButton
          size={30}
          variant='ghost'
          color='brand'
          icon={<BiArrowBack size={30} />}
          onClick={() => navigate('/settings/course')}
        />
      </Flex>
      <Center>
        <Heading my={10}>CREATE COURSE</Heading>
      </Center>
      <Heading as='h5' size='sm' my={2} color='brand'>
        Course Information
      </Heading>
      <Box p={5} borderRadius={10} mb={5} borderWidth='1px' borderColor='brand'>
        <Box mx={{ base: 0, md: 20, lg: 40, xl: 60 }}>
          <FormInputs
            errors={errors}
            control={control}
            forms={courseForm || []}
            data={data}
          />
        </Box>
      </Box>
      <Heading as='h5' size='sm' my={2} color='brand'>
        Course Program
      </Heading>
      <Box
        p={5}
        border='1px solid brand'
        borderRadius={10}
        borderWidth='1px'
        borderColor='brand'
      >
        <Box>
          <SimpleGrid
            columns={[1, 1, 1, 5]}
            spacing={[1, 1, 1, '25px']}
            // mx={{ base: 0, md: 20, lg: 10 }}
            justifyContent='center'
            alignItems='center'
          >
            {fields.map((item, i) => {
              return (
                <Fragment key={i}>
                  {programForm.map((data) => (
                    <FormControl
                      key={`programs.${i}.${data.name}`}
                      mb='3'
                      isInvalid={errors.programs?.[i]?.[data.name]}
                      id={`programs[${i}]name`}
                    >
                      <FormLabel>{`${data.label}`}</FormLabel>
                      {data.type === 'color' ? (
                        <Controller
                          control={control}
                          name={`programs.${i}.${data.name}`}
                          render={({ field: { onChange, value } }) => (
                            <ColorPickerInput
                              onChange={onChange}
                              value={value || '#FFF'}
                            />
                          )}
                        />
                      ) : data.type === 'text' ? (
                        <Input
                          {...register(`programs.${i}.${data.name}`, {
                            required: data.required,
                          })}
                          name={`programs.${i}.${data.name}`}
                          type={data.type}
                          placeholder={data.placeholder}
                          defaultValue={data.defaultValue}
                        />
                      ) : (
                        <NumberInput
                          name={`programs.${i}.${data.name}`}
                          type={data.type}
                          placeholder={data.placeholder}
                          defaultValue={data.defaultValue}
                        >
                          <NumberInputField
                            {...register(`programs.${i}.${data.name}`, {
                              required: data.required,
                            })}
                          />
                        </NumberInput>
                      )}
                      <FormErrorMessage>
                        {errors.programs?.[i]?.[data.name] &&
                          `${data?.label} is Require Field `}
                      </FormErrorMessage>
                    </FormControl>
                  ))}
                  <Flex justify={{ base: 'center', lg: 'flex-start' }}>
                    <IconButton
                      p={0}
                      mt={4}
                      // variant='ghost'
                      onClick={async () => {
                        remove(i)
                      }}
                      colorScheme='red'
                      icon={<AiFillDelete />}
                    >
                      Delete
                    </IconButton>
                  </Flex>
                </Fragment>
              )
            })}
          </SimpleGrid>

          <Center>
            <Button
              colorScheme='lime'
              bg='brand'
              mt='5'
              mb='5'
              width={['100%', '100%', '30%', '20%']}
              type='button'
              onClick={() => addInput()}
            >
              + Add Program
            </Button>
          </Center>
        </Box>

        {programs?.length > 0 && (
          <TableContainer borderWidth={1} w={'100%'} borderRadius={10} mt={10}>
            <Table>
              <Thead>
                <Tr>
                  {header?.map((header) => (
                    <Th w={'20%'} key={header}>
                      {header}
                    </Th>
                  ))}
                </Tr>
              </Thead>
              <Tbody>
                {programs?.map((data, index) => (
                  <Tr key={index}>
                    <Td>{data.choice}</Td>
                    <Td>
                      {data.amount
                        ? `${Number(data.amount).toLocaleString('th-TH')}`
                        : ''}
                    </Td>
                    <Td>{data.unit}</Td>
                    <Td>
                      {data.price
                        ? `${Number(data.price).toLocaleString('th-TH')} บาท`
                        : ''}
                    </Td>
                    <Td>
                      <ColorPickerInput value={data.color} disable />
                    </Td>
                  </Tr>
                ))}
              </Tbody>
            </Table>
          </TableContainer>
        )}
      </Box>

      <HStack justifyContent='center' my={10}>
        <Button mr={3} variant='outline' onClick={onOpen}>
          Cancel
        </Button>
        <Button
          colorScheme='lime'
          bg='brand'
          onClick={handleSubmit(submit)}
          isLoading={isLoading}
        >
          Submit
        </Button>
      </HStack>
    </Box>
  )
}
