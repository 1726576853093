import { initializeApp } from 'firebase/app'
import { getFunctions, connectFunctionsEmulator } from 'firebase/functions'
import { getAuth, connectAuthEmulator } from 'firebase/auth'
import { getFirestore, connectFirestoreEmulator } from 'firebase/firestore'
import { getStorage, connectStorageEmulator } from 'firebase/storage'

const config = {
  apiKey: 'AIzaSyAqFNXL34Q091BeH0UFTkpy2hSiQ9cRPr0',
  authDomain: 'kidsplus-dev.firebaseapp.com',
  projectId: 'kidsplus-dev',
  storageBucket: 'kidsplus-dev.appspot.com',
  messagingSenderId: '296396554393',
  appId: '1:296396554393:web:e59f0aab73554e8884b808',
}

initializeApp(config)

const functions = getFunctions()
const auth = getAuth()
const db = getFirestore()
const storage = getStorage()

if (window.location.hostname === 'localhost') {
  connectAuthEmulator(auth, 'http://localhost:9099')
  connectFunctionsEmulator(functions, 'localhost', 5001)
  connectFirestoreEmulator(db, 'localhost', 8080)
  connectStorageEmulator(storage, 'localhost', 9199)
} else {
}

export { functions, auth, storage, db }
