import { useState, useEffect, useMemo } from 'react'
import {
  Flex,
  Heading,
  HStack,
  Button,
  IconButton,
  useDisclosure,
  useToast,
  Center,
  Box,
  Stack,
  Text,
} from '@chakra-ui/react'
import {
  onSnapshot,
  query,
  collection,
  deleteDoc,
  orderBy,
  doc,
} from 'firebase/firestore'
import { Table, Popconfirm } from 'antd'
import { db } from 'config/firebase'
import { AiFillEdit, AiFillDelete } from 'react-icons/ai'
import { useParams } from 'react-router-dom'

import EvaluationModal from './Modals/evaluation'
import ActiveModal from './Modals/active'

export default function Office() {
  const { id } = useParams()

  const toast = useToast()
  const [evaluation, setEvaluation] = useState(null)
  const [activeMenber, setActiveKid] = useState(null)

  const [isLoadingEvalution, setLoadingEvalution] = useState(true)
  const [evaluationList, setdataEvaluationList] = useState()

  const [isLoadingActive, setLoadingActive] = useState(true)
  const [activeList, setActiveList] = useState()

  const {
    isOpen: isOpenEvaluation,
    onOpen: onOpenEvaluation,
    onClose: onCloseEvaluation,
  } = useDisclosure()

  const {
    isOpen: isOpenActive,
    onOpen: onOpenActive,
    onClose: onCloseActive,
  } = useDisclosure()

  useEffect(() => {
    if (id) {
      const queryKid = query(
        collection(db, `Kids/${id}/Evaluation`),
        orderBy('createdAt', 'desc')
      )
      const unsubscribe = onSnapshot(queryKid, (snapShot) => {
        const data = []
        snapShot.forEach((snapShot) => {
          data.push({ ...snapShot.data(), id: snapShot.id })
        })
        setdataEvaluationList(data)
        setLoadingEvalution(false)
      })
      return () => {
        unsubscribe()
      }
    }
  }, [id])

  useEffect(() => {
    if (id) {
      const queryKid = query(
        collection(db, `Kids/${id}/Status`),
        orderBy('createdAt', 'desc')
      )
      const unsubscribe = onSnapshot(queryKid, (snapShot) => {
        const data = []
        snapShot.forEach((snapShot) => {
          data.push({ ...snapShot.data(), id: snapShot.id })
        })
        setActiveList(data)
        setLoadingActive(false)
      })
      return () => {
        unsubscribe()
      }
    }
  }, [id])

  const columns = useMemo(() => {
    return [
      {
        title: 'Evaluation',
        dataIndex: 'type',
        key: 'type',
        width: '20%',
      },
      {
        title: 'Evaluation Date',
        dataIndex: 'date',
        key: 'date',
        width: '20%',
      },
      {
        title: 'Evaluation Fee',
        dataIndex: 'fee',
        key: 'fee',
        width: '20%',
      },
      {
        title: 'Evaluation Staff',
        dataIndex: 'staff',
        key: 'staff',
        width: '20%',
      },
      {
        title: 'Action',
        dataIndex: 'Action',
        key: 'Action',
        width: '10%',
        render: (data, row) => {
          return (
            <HStack justifyContent='space-between'>
              <HStack spacing={2}>
                <IconButton
                  variant='link'
                  aria-label='edit'
                  icon={<AiFillEdit color='brand' fontSize='25px' />}
                  onClick={() => {
                    setEvaluation(row)
                    onOpenEvaluation()
                  }}
                />
                <Popconfirm
                  title='Sure to delete this Evaluation'
                  onConfirm={() => {
                    handleDelete('Evaluation', row.id)
                  }}
                >
                  <IconButton
                    variant='link'
                    aria-label='delete'
                    icon={<AiFillDelete color='red' fontSize='25px' />}
                  />
                </Popconfirm>
              </HStack>
            </HStack>
          )
        },
      },
    ]
    // eslint-disable-next-line
  }, [])

  const columnsActive = useMemo(() => {
    return [
      {
        title: '1st Treatment Date',
        dataIndex: 'activeDate',
        key: 'activeDate',
      },
      {
        title: 'Kid Expire Date',
        dataIndex: 'expireDate',
        key: 'expireDate',
      },
      {
        title: 'Kid Fee',
        dataIndex: 'fee',
        key: 'fee',
      },
      {
        title: 'Off/Discharge Date',
        dataIndex: 'offDate',
        key: 'offDate',
      },

      {
        title: 'Return Date',
        dataIndex: 'returnDate',
        key: 'returnDate',
      },
      {
        title: 'Status',
        dataIndex: 'status',
        key: 'status',
        render: (status) => (
          <Text
            textTransform={'uppercase'}
            color={status === 'member' && 'brand'}
          >
            {status}
          </Text>
        ),
      },
      {
        title: 'Action',
        dataIndex: 'Action',
        key: 'Action',
        width: '10%',
        render: (data, row) => {
          return (
            <HStack justifyContent='space-between'>
              <HStack spacing={2}>
                <IconButton
                  variant='link'
                  aria-label='edit'
                  icon={<AiFillEdit color='brand' fontSize='25px' />}
                  onClick={() => {
                    setActiveKid(row)
                    onOpenActive()
                  }}
                />
                <Popconfirm
                  title='Sure to delete this Branch'
                  onConfirm={() => {
                    handleDelete('Status', row.id)
                  }}
                >
                  <IconButton
                    variant='link'
                    aria-label='delete'
                    icon={<AiFillDelete color='red' fontSize='25px' />}
                  />
                </Popconfirm>
              </HStack>
            </HStack>
          )
        },
      },
    ]
    // eslint-disable-next-line
  }, [])

  function handleDelete(collection, docId) {
    console.log(doc(db, `Kids/${id}/${collection}`, docId))
    deleteDoc(doc(db, `Kids/${id}/${collection}`, docId))
      .then(() => {
        toast({
          position: 'top',
          title: 'Delete Evaluation',
          description: 'Evaluation has been Deleted.',
          status: 'success',
          duration: 3000,
          isClosable: true,
        })
      })
      .catch((e) => {
        toast({
          position: 'top',
          title: 'Delete Fail.',
          description: e.message,
          status: 'error',
          duration: 3000,
          isClosable: true,
        })
      })
  }

  return (
    <>
      <Center my='10'>
        <Heading>FOR OFFFICE</Heading>
      </Center>

      <Stack spacing={10}>
        <Box>
          <Heading as='h5' size='sm' my={2} color='#FFC211'>
            Kid Evaluation
          </Heading>
          <Box
            borderWidth='1px'
            borderColor={'#FFC211'}
            borderRadius='12px'
            p={5}
          >
            {isOpenEvaluation && (
              <EvaluationModal
                isOpen={isOpenEvaluation}
                onClose={() => {
                  setEvaluation(null)
                  onCloseEvaluation()
                }}
                data={evaluation}
                kidId={id}
              />
            )}
            <Flex justifyContent='flex-end' w={'100%'} my={5}>
              <Button
                variant='solid'
                colorScheme='brand'
                bg='brand'
                onClick={onOpenEvaluation}
              >
                Add Evaluation
              </Button>
            </Flex>
            <Table
              bordered
              rowClassName={() => 'rowClassName1'}
              size='small'
              columns={columns}
              dataSource={evaluationList}
              loading={isLoadingEvalution}
              rowKey='id'
              scroll={{ x: 'max-content' }}
            />
          </Box>
        </Box>
        <Box>
          <Heading as='h5' size='sm' my={2} color='brand'>
            Kid Status
          </Heading>
          <Box
            borderWidth='1px'
            borderColor={'brand'}
            borderRadius='12px'
            p={5}
          >
            {isOpenActive && (
              <ActiveModal
                isOpen={isOpenActive}
                onClose={() => {
                  setActiveKid(null)
                  onCloseActive()
                }}
                data={activeMenber}
                kidId={id}
              />
            )}
            <Flex justifyContent='flex-end' w={'100%'} my={5}>
              <Button
                variant='solid'
                colorScheme='brand'
                bg='brand'
                onClick={onOpenActive}
              >
                Update Kid Status
              </Button>
            </Flex>
            <Table
              bordered
              style={{ borderRadius: '5px' }}
              size='small'
              dataSource={activeList}
              columns={columnsActive}
              loading={isLoadingActive}
              rowKey='id'
              scroll={{ x: 'max-content' }}
            />
          </Box>
        </Box>
      </Stack>
    </>
  )
}
