import { Box, Flex, IconButton } from '@chakra-ui/react'

import { FaRegFilePowerpoint } from 'react-icons/fa'
import { BiUser } from 'react-icons/bi'
import {
  AiOutlineSchedule,
  AiOutlineCreditCard,
  AiFillSetting,
  AiOutlineClose,
} from 'react-icons/ai'

import NavLink from './NavLink'

const LinkItems = [
  { label: 'Kids', icon: BiUser, href: '/kids' },
  { label: 'Schedule', icon: AiOutlineSchedule, href: '/#' },
  { label: 'Payment', icon: AiOutlineCreditCard, href: '/payments' },
  { label: 'Report', icon: FaRegFilePowerpoint, href: '/#' },
  { label: 'Settings', icon: AiFillSetting, href: '/settings' },
]

export default function Sidebar({ onClose, ...rest }) {
  return (
    <Box
      bg='brand'
      boxShadow='4px 4px 31px rgba(0, 0, 0, 0.1)'
      w={{ base: 'full', md: '100px' }}
      pos='fixed'
      h='full'
      pt={{ base: 0, md: '100px' }}
      {...rest}
    >
      <Flex justifyContent='flex-end' overflowY='auto'>
        <IconButton
          display={{ base: 'flex', md: 'none' }}
          onClick={onClose}
          variant='outline'
          aria-label='open menu'
          icon={<AiOutlineClose />}
        />
      </Flex>
      {LinkItems.map((link, i) => (
        <NavLink key={i} link={link} onClose={onClose} />
      ))}
    </Box>
  )
}
