import { Text, SimpleGrid, Flex, Icon } from '@chakra-ui/react'
import { NavLink as ReactNavLink } from 'react-router-dom'

import { FaBookReader } from 'react-icons/fa'
import { BiStore } from 'react-icons/bi'
import { RiUserSettingsFill } from 'react-icons/ri'
import { AiFillFileText } from 'react-icons/ai'

const LinkItems = [
  { label: 'Staff Setting', icon: FaBookReader, href: '/settings/staff' },
  { label: 'Admin Setting', icon: RiUserSettingsFill, href: '/settings/admin' },
  { label: 'Course Setting', icon: AiFillFileText, href: '/settings/course' },
  { label: 'Branch Setting', icon: BiStore, href: '/settings/branch' },
]

export default function Settings() {
  return (
    <SimpleGrid columns={[1, null, 2]} spacing='60px' p={{ base: 10, lg: 20 }}>
      {LinkItems.map((link, i) => (
        <ReactNavLink to={link?.href} key={i}>
          <Flex
            flexDirection='column'
            align='center'
            justifyContent='center'
            p='2'
            m='1'
            h='200px'
            borderRadius='md'
            role='group'
            cursor='pointer'
            _hover={{
              bg: 'rgba(255, 255, 255, 0.5)',
            }}
            color='white'
            borderWidth='2px'
            borderColor='brand'
          >
            {link?.icon && <Icon fontSize='60' color='brand' as={link?.icon} />}
            <Text fontSize='24px' color='#767262'>
              {link?.label}
            </Text>
          </Flex>
        </ReactNavLink>
      ))}
    </SimpleGrid>
  )
}
