/* eslint-disable react-hooks/exhaustive-deps */
import { useMemo, useState, useEffect, useRef } from 'react'
import {
  Button,
  Box,
  HStack,
  Heading,
  FormControl,
  Input,
  FormLabel,
  FormErrorMessage,
  IconButton,
  useToast,
  Flex,
  NumberInput,
  NumberInputField,
  useDisclosure,
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
  AlertDialogCloseButton,
  VStack,
  Text,
  Stack,
  Image,
} from '@chakra-ui/react'
import { useForm, useFieldArray, Controller } from 'react-hook-form'
import { Link, useNavigate, useParams } from 'react-router-dom'
import { AiFillDelete } from 'react-icons/ai'
import { db } from 'config/firebase'
import {
  onSnapshot,
  query,
  doc,
  collection,
  writeBatch,
  where,
  getDocs,
  orderBy,
} from 'firebase/firestore'
import { BiArrowBack } from 'react-icons/bi'
import Select from 'react-select'

import FormInputs from 'components/DynamicForm'
import ColorPickerInput from 'components/ColorPickerInput'

const label = {
  en: {
    kidCode: 'KID CODE',
    name: 'Customer',
    branch: 'Branch',
    address: 'Address',
    payment: 'Payment',
    no: 'No.',
    description: 'Description',
    price: 'Amount(THB)',
    totalPrice: 'Total',
    currency: 'BAHT',
    bill: 'CASH SALE',
    receipt: 'RECEIPT',
  },
  th: {
    kidCode: 'รหัสเด็ก',
    name: 'ชื่อลูกค้า',
    branch: 'สาขา',
    address: 'ที่อยุ่',
    payment: 'การชำระเงิน',
    no: 'ลำดับ',
    description: 'รายการ',
    price: 'จำนวนเงิน(บาท)',
    totalPrice: 'รวมเป็นเงิน',
    currency: 'บาท',
    bill: 'สร้างบิลเงินสด',
    receipt: 'สร้างใบเสร็จรับเงิน',
  },
}

export default function PaymentForm({ type, user }) {
  const navigate = useNavigate()
  const { id } = useParams()
  const toast = useToast()
  const [isLoading, setLoading] = useState(false)
  const [isLoadingData, setLoadingData] = useState(false)
  const { isOpen, onOpen, onClose } = useDisclosure()
  const cancelRef = useRef()
  const [paymentId, setPaymentId] = useState(id)
  const [allKid, setAllKids] = useState()
  const [branches, setBranches] = useState([])
  const [chennels, setChannel] = useState([])
  const [kidId, setKidId] = useState('')
  const [lang, setLang] = useState('th')

  const {
    handleSubmit,
    formState: { errors },
    control,
    resetField,
    register,
    getValues,
    watch,
    setValue,
  } = useForm()

  const watchPrograms = watch({ nest: true })

  const watchKidId = watch(`kidId`)

  const totalPrice = useMemo(() => {
    let array = getValues('programs') || []
    return (
      (array.length > 0 &&
        array.reduce(
          (acc, val) => acc + (val?.price ? Number(val.price) : 0),
          0
        )) ||
      0
    )
  }, [watchPrograms])

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'programs',
  })

  const kidForm = (branches, chennels) => [
    {
      label: label[lang]?.name,
      name: 'name',
      type: 'text',
      placeholder: '',
      defaultValue: '',
      required: true,
    },
    {
      label: label[lang]?.branch,
      name: 'branch',
      type: 'dropdown',
      options: branches,
      placeholder: '',
      defaultValue: '',
      required: true,
    },

    {
      label: label[lang]?.address,
      name: 'address',
      type: 'textarea',
      placeholder: '',
      defaultValue: '',
      required: true,
    },
    {
      label: label[lang]?.payment,
      name: 'payment',
      type: 'dropdown',
      options: chennels,
      placeholder: '',
      defaultValue: '',
      required: true,
    },
  ]
  const programForm = [
    {
      label: label[lang]?.description,
      name: 'description',
      type: 'text',
      placeholder: '',
      defaultValue: '',
      required: true,
    },
    {
      label: label[lang]?.price,
      name: 'price',
      type: 'number',
      placeholder: '',
      defaultValue: '',
      required: true,
    },
  ]

  useEffect(() => {
    if (watchKidId?.label !== kidId) {
      resetField('programs')
      setKidId(watchKidId?.label)
      getPrograms(watchKidId?.label)
    }
  }, [watchKidId])

  useEffect(() => {
    getPaymentId()
  }, [])

  useEffect(() => {
    const queryBranches = query(collection(db, 'Branches'))
    const unsubscribe = onSnapshot(queryBranches, (snapShot) => {
      let data = []
      snapShot.forEach((doc) => {
        data.push(doc.data().branchName)
      })
      setBranches(data)
    })
    return () => {
      unsubscribe()
    }
  }, [])

  useEffect(() => {
    const queryChannel = query(collection(db, 'PaymentTypes'))
    const unsubscribe = onSnapshot(queryChannel, (snapShot) => {
      let data = []
      snapShot.forEach((doc) => {
        const name = doc.data().name
          ? doc.data().name
          : `${doc.data().bank} ${doc.data().branch} ${doc.data().accountNo}`
        data.push(name)
      })
      setChannel(data)
    })
    return () => {
      unsubscribe()
    }
  }, [])

  useEffect(() => {
    const queryKid = query(collection(db, 'Kids'))
    const unsubscribe = onSnapshot(queryKid, (snapShot) => {
      setLoadingData(true)
      let data = []
      snapShot.forEach((doc) => {
        data.push({
          label: doc.id,
          value: { ...doc.data(), id: doc.id },
        })
      })
      setAllKids(data)

      setLoadingData(false)
    })
    return () => {
      unsubscribe()
    }
  }, [])

  async function getPrograms(id) {
    const evaluationSnap = await getDocs(
      query(
        collection(db, `Kids/${id}/Evaluation`),
        orderBy('createdAt', 'desc')
      )
    )
    const statusSnap = await getDocs(
      query(collection(db, `Kids/${id}/Status`), orderBy('createdAt', 'desc'))
    )
    const programsSnap = await getDocs(
      query(collection(db, `Kids/${id}/Programs`), orderBy('createdAt', 'desc'))
    )

    evaluationSnap.forEach((doc) => {
      if (!(doc.data()?.payment?.status === 'paid')) {
        append({
          id: doc.id,
          description: doc.data()?.type,
          price: doc.data()?.fee,
          collection: 'Evaluation',
        })
      }
    })

    statusSnap.forEach((doc) => {
      if (
        !(doc.data()?.payment?.status === 'paid') &&
        doc.data().status === 'member'
      ) {
        append({
          id: doc.id,
          description: `Member for ${doc.data()?.activeDate} - ${
            doc.data()?.expireDate
          }`,
          price: doc.data()?.fee,
          collection: 'Status',
        })
      }
    })
    programsSnap.forEach((doc) => {
      if (!(doc.data()?.payment?.status === 'paid')) {
        append({
          id: doc.id,
          description: doc.data()?.name,
          price: doc.data()?.price,
          collection: 'Programs',
        })
      }
    })
  }

  async function getPaymentId() {
    const typeQuery = query(
      collection(db, 'Payments'),
      where('type', '==', type)
    )

    const typeSnapshot = await getDocs(typeQuery)

    const paymentId = `${type === 'bill' ? 'CH' : 'RE'}${String(
      typeSnapshot.size + 1
    ).padStart(6, '0')}`

    setPaymentId(paymentId)
  }

  function addInput() {
    append({
      description: '',
      price: 0,
    })
  }

  async function createPayment(values) {
    try {
      const batch = writeBatch(db)
      const { address, branch, name, payment, programs } = values
      const data = {
        address,
        branch,
        name,
        payment,
        type,
        kidId,
        totalPrice,
        programs,
        createdAt: new Date(),
        createdBy: user?.email,
        status: 'paid',
        language: lang,
      }

      batch.set(doc(db, 'Payments', paymentId), {
        ...data,
      })

      await programs.forEach((program) => {
        if (program?.id) {
          batch.set(
            doc(db, `Kids/${kidId}/${program?.collection}`, program?.id),
            {
              payment: {
                createdBy: user?.email,
                createdAt: new Date(),
                paymentNo: paymentId,
                status: 'paid',
              },
            },
            { merge: true }
          )
        }
      })

      await batch.commit()

      toast({
        position: 'top',
        title: 'Create Payment',
        description: 'Payment has been created.',
        status: 'success',
        duration: 3000,
        isClosable: true,
      })
      setLoading(false)
      resetField()
      navigate('/payments')
    } catch (e) {
      setLoading(false)
      toast({
        position: 'top',
        title: 'Create Fail.',
        description: e.message,
        status: 'error',
        duration: 3000,
        isClosable: true,
      })
    }
  }

  return (
    <Box p={{ base: 2, lg: 10 }}>
      <AlertDialog
        motionPreset='slideInBottom'
        leastDestructiveRef={cancelRef}
        onClose={onClose}
        isOpen={isOpen}
        isCentered
      >
        <AlertDialogOverlay />

        <AlertDialogContent>
          <AlertDialogHeader>Discard Changes?</AlertDialogHeader>
          <AlertDialogCloseButton />
          <AlertDialogBody>
            Are you sure you want to discard all of your input
          </AlertDialogBody>
          <AlertDialogFooter>
            <Button ref={cancelRef} onClick={onClose}>
              No
            </Button>
            <Link to={'/payments'}>
              <Button colorScheme='red' ml={3}>
                Yes
              </Button>
            </Link>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>
      <Flex justifyContent='flex-start'>
        <IconButton
          size={30}
          variant='ghost'
          color='brand'
          icon={<BiArrowBack size={30} />}
          onClick={() => navigate('/payments')}
        />
      </Flex>
      <Flex justifyContent='space-between' mt={6}>
        <VStack alignItems={'start'} spacing={1}>
          <Heading fontSize='2xl' color='#767262'>
            {label[lang][type]}
          </Heading>
          <Text fontSize='xl' color='#767262'>
            {paymentId}
          </Text>
        </VStack>
        <HStack spacing={6}>
          <Box
            as='button'
            color='#767262'
            spacing={0}
            onClick={() => setLang('th')}
          >
            <Image src='/flags/th.svg' />
            <Text fontSize='sm' color='#767262'>
              ไทย
            </Text>
          </Box>
          <Box
            as='button'
            color='#767262'
            spacing={0}
            onClick={() => setLang('en')}
          >
            <Image src='/flags/en.svg' />
            <Text fontSize='sm' color='#767262'>
              EN
            </Text>
          </Box>
        </HStack>
      </Flex>
      <Box p={5} borderRadius={10} mb={5} borderWidth='1px' borderColor='brand'>
        <Box mx={{ base: 0, md: 20, lg: 40, xl: 60 }}>
          <FormControl
            my={3}
            id='kidId'
            name='kidId'
            isInvalid={true}
            w={'100%'}
          >
            <FormLabel>{label[lang].kidCode}</FormLabel>
            <Controller
              name='kidId'
              control={control}
              rules={{
                required: 'Please select KID CODE',
              }}
              defaultValue={''}
              render={({ field: { onChange, value } }) => (
                <Select
                  options={allKid}
                  onChange={(option) => {
                    onChange(option)
                    setValue('name', option?.value?.nameTH)
                    setValue('branch', option?.value?.branch)
                    setValue('address', option?.value?.address)
                  }}
                  value={value}
                  isLoading={isLoadingData}
                />
              )}
            />
            <FormErrorMessage>
              {errors.kidId && errors.kidId.message}
            </FormErrorMessage>
          </FormControl>
          <FormInputs
            errors={errors}
            control={control}
            forms={kidForm(branches, chennels) || []}
            data={null}
          />
        </Box>
      </Box>
      <Heading as='h5' size='sm' my={2} color='brand'>
        Payment Program
      </Heading>
      <Box
        p={5}
        border='1px solid brand'
        borderRadius={10}
        borderWidth='1px'
        borderColor='brand'
      >
        <Box>
          {fields.map((item, i) => {
            return (
              <Stack
                direction={{ base: 'column', md: 'row' }}
                key={i}
                spacing={2}
                my={4}
                alignItems='end'
              >
                <Text
                  textAlign={{ base: 'start', md: 'center' }}
                  fontSize='xl'
                  w={{ base: 'full', md: '15%' }}
                >
                  {label[lang].no} {i + 1}
                </Text>
                {programForm.map((data) => (
                  <FormControl
                    key={`programs.${i}.${data.name}`}
                    mb='3'
                    isInvalid={errors.programs?.[i]?.[data.name]}
                    id={`programs[${i}]name`}
                  >
                    <FormLabel>{`${data.label}`}</FormLabel>
                    {data.type === 'color' ? (
                      <Controller
                        control={control}
                        name={`programs.${i}.${data.name}`}
                        render={({ field: { onChange, value } }) => (
                          <ColorPickerInput
                            onChange={onChange}
                            value={value || '#FFF'}
                          />
                        )}
                      />
                    ) : data.type === 'text' ? (
                      <Input
                        {...register(`programs.${i}.${data.name}`, {
                          required: data.required,
                        })}
                        name={`programs.${i}.${data.name}`}
                        type={data.type}
                        placeholder={data.placeholder}
                        defaultValue={data.defaultValue}
                      />
                    ) : (
                      <NumberInput
                        name={`programs.${i}.${data.name}`}
                        type={data.type}
                        placeholder={data.placeholder}
                        defaultValue={data.defaultValue}
                      >
                        <NumberInputField
                          {...register(`programs.${i}.${data.name}`, {
                            required: data.required,
                          })}
                        />
                      </NumberInput>
                    )}
                    <FormErrorMessage>
                      {errors.programs?.[i]?.[data.name] &&
                        `${data?.label} is Require Field `}
                    </FormErrorMessage>
                  </FormControl>
                ))}
                <IconButton
                  w={{ base: 'full', md: 'auto' }}
                  p={0}
                  onClick={async () => {
                    remove(i)
                  }}
                  colorScheme='red'
                  icon={<AiFillDelete />}
                >
                  Delete
                </IconButton>
              </Stack>
            )
          })}

          <Box>
            <Button
              colorScheme='orange'
              bg='#F6872B'
              mt='5'
              mb='5'
              width={['100%', '100%', '30%', '20%']}
              type='button'
              onClick={() => addInput()}
            >
              + Add
            </Button>
          </Box>

          <Flex justifyContent={'flex-end'} alignItems='center'>
            <HStack spacing={10}>
              <Heading>{label[lang]?.totalPrice}</Heading>
              <Heading fontSize='2xl' color='#767262'>
                {totalPrice.toLocaleString()}
              </Heading>
              <Heading fontSize='2xl' color='#767262'>
                {label[lang]?.currency}
              </Heading>
            </HStack>
          </Flex>
        </Box>
      </Box>

      <HStack justifyContent='center' my={10}>
        <Button mr={3} variant='outline' onClick={onOpen}>
          Cancel
        </Button>
        <Button
          colorScheme='lime'
          bg='brand'
          onClick={handleSubmit(createPayment)}
          isLoading={isLoading}
        >
          Submit
        </Button>
      </HStack>
    </Box>
  )
}
