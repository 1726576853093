import {
  Box,
  Tab,
  Tabs,
  TabList,
  TabPanels,
  TabPanel,
  Flex,
  IconButton,
} from '@chakra-ui/react'
import { BiArrowBack } from 'react-icons/bi'
import { useNavigate } from 'react-router-dom'

import KidForm from 'pages/Kids/form'
import Office from 'pages/Kids/Office'
import Development from 'pages/Kids/Development'
import Course from 'pages/Kids/Course'
import KidPayment from './Payment'

export default function KidInfo({ user }) {
  const navigate = useNavigate()

  return (
    <Box p={{ base: 0, lg: 10 }}>
      <Flex justifyContent='flex-start'>
        <IconButton
          size={30}
          variant='ghost'
          color='brand'
          icon={<BiArrowBack size={30} />}
          onClick={() => navigate('/kids')}
        />
      </Flex>
      <Tabs variant='unstyled' mt={'2rem'}>
        <TabList overflowX='auto'>
          <Tab
            _focus={{ boxShadow: 'none' }}
            _selected={{ color: 'white', bg: 'brand' }}
            borderBottom='2px solid rgba(226, 232, 240, 1)'
          >
            KID INFO
          </Tab>
          <Tab
            _focus={{ boxShadow: 'none' }}
            _selected={{ color: 'white', bg: 'brand' }}
            borderBottom='2px solid rgba(226, 232, 240, 1)'
          >
            FOR OFFFICE
          </Tab>
          <Tab
            _focus={{ boxShadow: 'none' }}
            _selected={{ color: 'white', bg: 'brand' }}
            borderBottom='2px solid rgba(226, 232, 240, 1)'
          >
            DEVELOPMENT
          </Tab>
          <Tab
            _focus={{ boxShadow: 'none' }}
            _selected={{ color: 'white', bg: 'brand' }}
            borderBottom='2px solid rgba(226, 232, 240, 1)'
          >
            COURSES
          </Tab>
          <Tab
            _focus={{ boxShadow: 'none' }}
            _selected={{ color: 'white', bg: 'brand' }}
            borderBottom='2px solid rgba(226, 232, 240, 1)'
          >
            PAYMENTS
          </Tab>
        </TabList>
        <TabPanels>
          <TabPanel>
            <KidForm />
          </TabPanel>
          <TabPanel>
            <Office />
          </TabPanel>
          <TabPanel>
            <Development />
          </TabPanel>
          <TabPanel>
            <Course />
          </TabPanel>
          <TabPanel>
            <KidPayment user={user} />
          </TabPanel>
        </TabPanels>
      </Tabs>
    </Box>
  )
}
